import React from "react";
import { CompletedTexts } from "../languages/GetTexts";
import { CompletedTextsType } from "../languages/types";
import ReactHtmlParser from "react-html-parser";
import { LanguageType } from "../types";

const Completed = ({language}: {language: LanguageType }) => {

    const texts: CompletedTextsType = CompletedTexts(language);

    return (
        <div>
            <h1 className="text-center mt-4 pt-4">
                {texts.title}<br />
                <small>{ReactHtmlParser(texts.description)}</small>
            </h1>
            <p className="text-center">{texts.close}</p>
        </div>
    );
};

export default Completed;
