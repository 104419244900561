const texts = {
    title: 'PERSOONLIJKHEIDSTEST',
    description: `De bedoeling is dat je telkens aanduidt in welke mate de
statements die je te zien krijgt, voor jou meest van
    toepassing zijn.
    <br />
    Vul de test in op basis van hoe je NU denkt en bent in een
    professionele context.
    <br />
    <br />
    Bedankt en succes!`,
    privacy_first: 'Ik heb het',
    privacy_link: 'Privacy beleid',
    privacy_last: 'gelezen en ga hier mee akkoord',
    start: 'Start de test'
};

export default texts;
