const texts = {
    explanation: `Je krijgt nu telkens een uitspraak.
    <br />
    Duidt aan van 1 tot 5 in welke mate deze uitspraak op
    jou van toepassing is,
    <br />
    waarbij 1 minst van toepassing en 5 meest van toepassing
    is.
    <br />`,
    scale_left: "Helemaal niet eens",
    scale_right: "Helemaal eens",
    button_next: "Volgende",
    button_start: "Ga naar uitspraken",
    questions: [
        { question: 1, text: "Ik doe het gauw zelf, dan weet ik dat het goed gedaan is." },
        { question: 2, text: "Fouten maken vind ik vreselijk, ik ben daar echt niet goed van. Immers, ik ben heel kritisch en controleer alles vooraleer iets als “af” te beschouwen." },
        { question: 3, text: "Regels zijn er om te volgen.<br />Het is niet correct regels te overtreden. Dat doe je gewoon niet!" },
        { question: 4, text: "Ik kan niet begrijpen waarom zoveel mensen er zulke lakse normen op na houden." },
        { question: 5, text: "Het kost mij moeite om mij niet met alles te willen bemoeien,<br />zodat het goed gedaan zou worden." },
        { question: 6, text: "De meeste mensen zien mij als iemand die serieus en zakelijk is…<br />ik kom ook meestal zo over." },

        { question: 7, text: "Ik kan moeilijk nee zeggen omdat ik dan denk<br />dat ik mensen teleur stel en dat vermijd ik." },
        { question: 8, text: "Ik zorg éérst voor de ander en dàn pas voor mijzelf.<br />Ik ben er immers om anderen te helpen." },
        { question: 9, text: "Als ik geen connectie voel, functioneer ik niet goed. Ik wil graag een beste vriend zijn<br />voor anderen en daarom heb ik de neiging te pleasen." },
        { question: 10, text: "Zelfs als mensen onverschillig zijn tegen mij,<br />ga ik er alles aan doen om ervoor te zorgen dat deze mensen mij graag hebben." },
        { question: 11, text: "Als ik een zwerfhond zie,<br />zou ik hem liefst direct meenemen naar huis." },
        { question: 12, text: "Ik hou er niet van om de eer op te strijken voor de vele dingen die ik voor anderen doe, maar het kan wel aan me knagen of me zelfs gaan dwarszitten als zij het niet opmerken of er precies niets om geven." },

        { question: 13, text: "Het is niet OK om niet OK te zijn.<br />Daarom geef ik altijd de indruk dat alles ok is." },
        { question: 14, text: "Falen is geen optie! Ik wil altijd winnen." },
        { question: 15, text: "Ik heb een duidelijk doel en moet dat<br />zo efficiënt (en liefst ook zo snel) mogelijk bereiken." },
        { question: 16, text: "Ik zie mijzelf als competent:<br />ik word er niet goed gezind van als ik niet effectief en efficiënt ben." },
        { question: 17, text: "Het is voor mij belangrijk dat ik mij succesvol kan voelen,<br />ook al heb ik nog niet het grote succes waar ik naar streef." },
        { question: 18, text: "Ik lijk een workaholic,<br />ik voel mij nutteloos en rusteloos als ik niet bezig ben om iets te bereiken." },

        { question: 19, text: "In alles wat ik doe moet een persoonlijke betekenis zitten.<br />Ik verlang naar diepgang in alles." },
        { question: 20, text: "Anderen begrijpen mij niet. Ik voel mij dikwijls onbegrepen." },
        { question: 21, text: "Ik wil geen grijze muis zijn; ik wil écht het verschil maken!" },
        { question: 22, text: "Veel mensen vinden mij raadselachtig, moeilijk en contradictorisch –<br />eigenlijk vind ik dat wel fijn." },
        { question: 23, text: "Mezelf leren kennen en trouw zijn aan mijn emotionele behoeften,<br />zijn altijd belangrijke drijfveren voor mij geweest." },
        { question: 24, text: "Als ik bekritiseerd of mis begrepen word,<br />heb ik de neiging me terug te trekken en zelfs te mokken." },

        { question: 25, text: "Ik hou mijn privé liefst gescheiden van mijn werk." },
        { question: 26, text: "Inzicht in zaken vind ik belangrijk; ik wil het “weten” en<br />zal zoeken tot ik meer kennis heb; je kan immers nooit genoeg weten." },
        { question: 27, text: "Ik kan mij uren-, zelfs dagenlang focussen op de materie die mij interesseert<br />en ik kan heel makkelijk lang alleen zijn." },
        { question: 28, text: "Alleen op grond van accurate informatie kan men een rationele beslissing nemen.<br />De meeste mensen zijn echter niet erg rationeel." },
        { question: 29, text: "De meeste mensen zijn zo ongelooflijk onnozel<br />dat het bijna een wonder is dat er überhaupt nog iets goed gaat." },
        { question: 30, text: "Ik ben zeer nieuwsgierig en onderzoek graag waarom dingen zijn zoals ze zijn.<br />Zelfs de meest evidente dingen zijn niet zo voor de hand liggend<br />als je ze werkelijk bestudeert." },

        { question: 31, text: "Ik heb een groot verantwoordelijkheidsgevoel en<br />ik vind samenwerken in vertrouwen heel erg belangrijk." },
        { question: 32, text: "Ik ben vrij snel ongerust en twijfel vaak." },
        { question: 33, text: "Ik ben graag op alles voorbereid en voel mij pas gerust als ik weet<br />waar ik aan toe ben en alles duidelijk is. Ik ben graag op voorhand voorzien<br />op zaken of mogelijke situaties." },
        { question: 34, text: "Autoriteit trekt mij aan.<br />Tegelijkertijd sta ik er wantrouwig tegenover." },
        { question: 35, text: "Ik voel mij zekerder als ik duidelijk weet wat van mij verwacht wordt<br />dan dat ik op eigen houtje zou tewerk gaan." },
        { question: 36, text: "Vooraleer ik een belangrijke beslissing neem, ga ik bij mensen die ik vertrouw hun mening polsen voordat ik effectief beslis. Meestal weet ik eigenlijk al vooraf wat mijn beslissing zou zijn maar het voelt goed om bevestiging te krijgen." },

        { question: 37, text: "Structuur en routine zijn doodsaai, dan sterf ik liever." },
        { question: 38, text: "Mijn hoofd zit vol ideeën; ik ben heel goed in brainstormen<br />over leuke en/of nieuwe zaken." },
        { question: 39, text: "Ik wil overal bij zijn en wil geen leuke dingen en/of ervaringen missen.<br />Stel je voor dat ik iets plezants zou missen! Daar zou ik echt van balen." },
        { question: 40, text: "Ik hou van reizen en avontuur en vind het fijn om andere culturen<br />te ontdekken en nieuwe ervaringen op te doen.<br />De wereld is immers een fantastische bron van nieuwe prikkels." },
        { question: 41, text: "Gewoonlijk heb ik een bomvolle agenda en dat hou ik ook liefst zo want ik wil vermijden dat ik mij zou gaan vervelen." },
        { question: 42, text: "Kleine details zijn niet zo aan mij besteed. Ik voel mij beter in het grotere plaatje. Het is tenslotte veel leuker om al brainstormend nieuwe ideeën te genereren dan betrokken te zijn bij de implementatie ervan." },

        { question: 43, text: "Niemand beslist voor mij; het is mijn leven!" },
        { question: 44, text: "Niet flauw doen, doorbijten!" },
        { question: 45, text: "Ik bepaal. Als men mij probeert te commanderen, voel ik weerstand.<br />De controle bewaren over mijn eigen handelingen is belangrijk – ik hou graag de touwtjes zelf in handen!" },
        { question: 46, text: "Ik kan geen sympathie opbrengen voor zwakkelingen en mensen die niet<br />meteen voor hun eigen mening durven uitkomen.<br />Zwakte veroorzaakt eigenlijk alleen maar problemen." },
        { question: 47, text: "In deze situatie ben ik echt heel bijzonder trots : als mensen die ik onder mijn vleugels gehad heb, groeien als mens en succesvol zijn in ondernemen of in hun job." },
        { question: 48, text: "Ik krijg een immens gevoel van voldoening als ik iets doe wat anderen onmogelijk achten. Ik geniet ervan van op het randje te lopen en dan te zien dat het me toch, tegen alle verwachtingen in, lukt." },

        { question: 49, text: "Ik ben een ‘go with the flow’ persoon;<br />ik vermijd conflicten en blijf liefst onder de radar." },
        { question: 50, text: "Mij kan je niet opjagen; ik laat mij niet opjagen en doe alles op mijn tempo. Ik vertoon soms uitstelgedrag maar als een deadline nadert, zal ik een tandje bijsteken." },
        { question: 51, text: "Ik ben gelukkig en tevreden als de mensen in mijn omgeving gelukkig en tevreden zijn. Dat geeft mij rust." },
        { question: 52, text: "Ik geef liever iemand zijn zin dan een scène te maken." },
        { question: 53, text: "Mensen zeggen soms dat ik afwezig of verstrooid lijk.<br />Het feit is dat ik dingen heel goed begrijp maar er simpelweg niet wil op reageren omdat ik mijn innerlijke rust niet wil verstoren." },
        { question: 54, text: "Je moet je neerleggen bij wat het leven brengt.<br />Je kan er sowieso toch zelf weinig aan doen." }
    ]
}

export default texts;
