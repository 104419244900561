const texts = {
    button_next: "Suivante",

    questions: {
        1: {
            8: [
                {
                    text:
                        'Sur quoi vous concentrez-vous <span class="important">plutôt</span>?',
                    options: { 8: "sur la vue d'ensemble", 1: "sur les détails" },
                    value: 3
                },
                {
                    text:
                        'Êtes-vous <span class="important">plutôt</span> décontracté ou contrôlé?',
                    options: { 8: "décontracté", 1: "contrôlé" },
                    value: 1
                },
                {
                    text:
                        'Vous décririez-vous comme quelqu\'un qui respecte les règles ou comme un non-conformiste?',
                    options: { 1: "respecte les règles", 8: "non-conformiste" },
                    value: 1
                }
            ],
            9: [
                {
                    text:
                        'Les erreurs des autres sont-elles plus susceptibles de vous ennuyer et de vous frustrer ou êtes-vous quelqu\'un qui n\'en dirait rien et penserait "ce n\'est pas si mal"?',
                    options: {
                        1: "agacer et frustrer",
                        9: "ne dirait rien et penserait \"ce n'est pas si mal\""
                    },
                    value: 1
                },
                {
                    text:
                        'Êtes-vous plus compatissant ou abordez-vous les choses de manière rationnelle?',
                    options: { 9: "compossible", 1: "rationnel" },
                    value: 1
                },
                {
                    text:
                        'Qu\'est-ce que vous n\'aimez pas <span class="important">le plus</span>?',
                    options: {
                        9: "que les autres vous poursuivent",
                        1: "que les autres vous critiquent"
                    },
                    value: 3
                }
            ]
        },
        8: {
            9: [
                {
                    text:
                        'Les autres sont-ils <span class="important">plus susceptibles</span> de dire que vous êtes "trop direct" ou "trop accommodant"?',
                    options: { 8: "trop direct", 9: "trop accommodant" },
                    value: 1
                },
                {
                    text:
                        'Êtes-vous plutôt patient ou plutôt impatient?',
                    options: { 9: "patient", 8: "plutôt impatient" },
                    value: 3
                },
                {
                    text:
                        'Que pensez-vous est <span class="important">le pire</span>?',
                    options: { 8: "impuissance", 9: "l'intrusion" },
                    value: 1
                }
            ]
        },

        2: {
            3: [
                {
                    text:
                        'Qu\'est-ce que tu ressens <span class="important">le plus</span>?<br/>Être perçu par les autres comme égoïste ou infructueux?',
                    options: { 2: "égoïste", 3: "infructueux" },
                    value: 3
                },
                {
                    text:
                        'Avec quoi avez-vous <span class="important">le plus</span> de difficultés ?',
                    options: {
                        2: "attitude détachée des autres",
                        3: "personnes lentes"
                    },
                    value: 1
                },
                {
                    text:
                        "Lorsque les autres vous brossent un tableau, préférez-vous qu'ils disent que vous êtes une personne utile ou qu'ils vous considèrent comme compétent et prospère?",
                    options: {
                        2: "personne serviable",
                        3: "compétent et efficace"
                    },
                    value: 1
                }
            ],
            4: [
                {
                    text: 'Que pensez-vous est <span class="important">pire</span>?',
                    options: { 2: "être le plaisir", 4: "être la souris grise" },
                    value: 1
                },
                {
                    text:
                        "Préféreriez-vous être un bon collègue ou préféreriez-vous vraiment faire la différence dans l'équipe?",
                    options: {
                        2: "être un bon collègue",
                        4: "faire la différence dans l'équipe"
                    },
                    value: 1
                },
                {
                    text:
                        '<span class="important">Préféreriez-vous être reconnu</span> pour votre serviabilité ou pour la personne que vous êtes?',
                    options: {
                        2: "votre serviabilité",
                        4: "pour la personne que vous êtes"
                    },
                    value: 3
                }
            ]
        },
        3: {
            4: [
                {
                    text:
                        'A quoi accordez-vous <span class="important">le plus</span> d\'importance?',
                    options: {
                        3: "être efficace",
                        4: "avoir de la profondeur dans votre travail"
                    },
                    value: 3
                },
                {
                    text: "Ce qui est le plus important pour vous:",
                    options: { 4: "introspection", 3: "construire votre carrière" },
                    value: 1
                },
                {
                    text: "Si quelqu'un vous approche émotionnellement, faites-vous:",
                    options: {
                        3: '<span class="important">plutôt</span> s\'éloigner',
                        4: '<span class="important">plutôt</span> soutenir'
                    },
                    value: 1
                }
            ]
        },

        5: {
            6: [
                {
                    text: "Qu'est-ce que tu choisis?",
                    options: {
                        6: "fort ensemble!",
                        5: "indépendance personnelle"
                    },
                    value: 3
                },
                {
                    text: "Ce qui vous dérange le plus?",
                    options: { 6: "solitaires", 5: "les gens insistants" },
                    value: 1
                },
                {
                    text: "Avec quoi trouvez-vous le plus difficile:",
                    options: {
                        6: "ambiguïté",
                        5: "revendiquant le comportement des autres"
                    },
                    value: 1
                }
            ],
            7: [
                {
                    text:
                        "Laquelle préférez-vous: en savoir beaucoup sur quelque chose ou en savoir sur beaucoup de choses différentes?",
                    options: {
                        5: "en savoir beaucoup sur quelque chose",
                        7: "en savoir plus sur beaucoup de choses différentes"
                    },
                    value: 1
                },
                {
                    text: "Ce qui vous dérange le plus:",
                    options: {
                        5: "la myopie chez les autres",
                        7: "la matité"
                    },
                    value: 1
                },
                {
                    text:
                        "Apparaissez-vous habituellement calme et calme aux autres ou plutôt joyeux et enthousiaste?",
                    options: {
                        5: "calme et tranquille",
                        7: "joyeux et enthousiaste"
                    },
                    value: 3
                }
            ]
        },
        6: {
            7: [
                {
                    text:
                        "Dans quel environnement préférez-vous travailler? Dans un environnement où il existe une structure claire et des directives et procédures claires à respecter ou préférez-vous un environnement dans lequel vous avez beaucoup de liberté dans ce que vous faites sans règles claires?",
                    options: {
                        6: "des directives et des procédures claires",
                        7: "beaucoup de liberté et pas de règles claires?"
                    },
                    value: 1
                },
                {
                    text:
                        "Qu'est-ce que vous aimez le plus: la prévisibilité ou la flexibilité?",
                    options: { 6: "prévisibilité", 7: "flexibilité" },
                    value: 1
                },
                {
                    text: "Lequel préférez-vous:",
                    options: { 6: "clarté", 7: "aventure" },
                    value: 3
                }
            ]
        }
    }
}

export default texts;
