import React, { useState } from "react";
import Round1 from "./Round1";
import Round2 from "./Round2";
import Round3 from "./Round3";
import Round4 from "./Round4";
import Round5 from "./Round5";
import Round6 from "./Round6";
import Round7 from "./Round7";
import { LanguageType, AnswerType, ProfileType, ResultType, SaveAnswerDataType, CompileResultType, ResultProfileType } from "../types";

type RenderRoundProps = {
    id: string;
    currentRound: number;
    currentQuestion: number;
    answers: AnswerType[];
    result: ResultType;
    competences: string[];
    language: LanguageType;
    setResult: (value: {} | ((prevVar: {}) => {})) => void;
    setAnswers: (value: AnswerType[] | ((prevVar: AnswerType[]) => AnswerType[])) => void;
    setCurrentQuestion:  (value: number | ((prevVar: number) => number)) => void;
    finishCurrentRound: () => void;
};

const RenderRound = ({
    id,
    language,
    currentRound,
    currentQuestion,
    result,
    answers,
    competences,
    setResult,
    setAnswers,
    setCurrentQuestion,
    finishCurrentRound
}: RenderRoundProps) => {

    const [timer, setTimer] = useState(Date.now());

    const compileResult = (data: CompileResultType | CompileResultType[]): void => {

        if (Array.isArray(data)) {
            data.forEach(item => {
                const value =
                    item.attr === "value"
                        ? result[item.type].value + item.value
                        : item.value;
                if(item.attr === "value") {
                    result[item.type].value = value;
                } else {
                    result[item.type].order = value;
                }
            });
        } else {
            const value =
                data.attr === "value"
                    ? result[data.type].value + data.value
                    : data.value;
            if(data.attr === "value") {
                result[data.type].value = value;
            } else {
                result[data.type].order = value;
            }
        }
        setResult(result);
    }

    const finishTest = () => {
        /*
        axios
            .post("/api/finish-test", { test_uuid: id })
            .then(res => {})
            .catch(error => {});
        */
        return timer;
    }

    const returnTypeByCenter = (center: ProfileType[]): ResultProfileType => {
        //return Object.values(this.state.result).filter(i => i.center && center.includes(i.type));
        const centers = returnTypesByCenter(center);
        return centers[0];
    }

    const returnTypesByCenter = (center: ProfileType[]): ResultProfileType[] => {
        return Object.values(result)
            .filter(i => center.includes(i.type as ProfileType))
            .sort((a, b) => b.value - a.value);
    }
    /*
    const getTopTypes = (size: number) => {
        return Object.values(result)
            .sort((a, b) => b.value - a.value)
            .slice(0, size)
            .map(i => i.type);
    }
    */

    const saveAnswer = (data: SaveAnswerDataType, result: CompileResultType[] | CompileResultType | null, finishRound: boolean = false) => {
        //const time = Date.now() - timer;
        /*
        const answer = {
            test_uuid: id,
            round: currentRound,
            question: currentQuestion,
            extra: data.extra,
            answer: data.answer,
            time: time
        };
        */
        
        /* Can be removed after graphql setup */
        if (result) {
            compileResult(result);
            finishTest();
        }

        setTimer(Date.now());
        /*
        axios
            .post("/api/save-answer", answer)
            .then(res => {
                setAnswers(answers => [...answers, res.data]);

                if (result) {
                    compileResult(result);
                }

                if (finishRound) {
                    if (
                        currentRound === 6 ||
                        currentRound === 7
                    ) {
                        finishTest();
                    }
                    finishCurrentRound();
                } else {
                    setCurrentQuestion(currentQuestion + 1);
                    setTimer(Date.now());
                }
            })
            .catch(error => {});
            */
    };

    const renderActiveRound = () => {
        switch (currentRound) {
            case 1:
                return (
                    <Round1
                        language={language}
                        currentQuestion={currentQuestion}
                        saveAnswer={(data, result, completed) =>
                            saveAnswer(data, result, completed)
                        }
                    />
                );
                
            case 2:
                return (
                    <Round2
                        language={language}
                        currentQuestion={currentQuestion}
                        saveAnswer={(data, result, completed) =>
                            saveAnswer(data, result, completed)
                        }
                        //answers={answers.filter(i => i.round === 2)}
                    />
                );

            case 3:
                return (
                    <Round3
                        language={language}
                        currentQuestion={currentQuestion}
                        saveAnswer={(data, result, completed) =>
                            saveAnswer(data, result, completed)
                        }
                    />
                );

            case 4:
                return (
                    <Round4
                        language={language}
                        currentQuestion={currentQuestion}
                        saveAnswer={(data, result, completed) =>
                            saveAnswer(data, result, completed)
                        }
                    />
                );

            case 5:
                const centers = {
                    1: returnTypesByCenter([8, 9, 1]),
                    2: returnTypesByCenter([2, 3, 4]),
                    3: returnTypesByCenter([5, 6, 7])
                };
                return (
                    <Round5
                        language={language}
                        currentQuestion={currentQuestion}
                        saveAnswer={(data, result, completed) =>
                            saveAnswer(data, result, completed)
                        }
                        types={centers}
                    />
                );

            case 6:
                const types = {
                    1: returnTypeByCenter([8, 9, 1]),
                    2: returnTypeByCenter([2, 3, 4]),
                    3: returnTypeByCenter([5, 6, 7])
                };
                return (
                    <Round6
                        language={language}
                        currentQuestion={currentQuestion}
                        saveAnswer={(data, result, completed) =>
                            saveAnswer(data, result, completed)
                        }
                        types={types}
                    />
                );

            case 7:
                return (
                    <Round7
                        language={language}
                        currentQuestion={currentQuestion}
                        competences={competences}
                        saveAnswer={(data, result, completed) =>
                            saveAnswer(data, result, completed)
                        }
                        //answers={answers.filter(i => i.round === 7)}
                    />
                );

            default:
                return <div>Loading...</div>;
        }
    };

    return renderActiveRound();
};

export default RenderRound;
