const texts = {
    button_next: "Suivante",

    questions: {
        8: {
            2: [
                {
                    text:
                        "Êtes-vous plus susceptible de donner des indices lorsque vous voulez quelque chose ou dites-vous simplement ce que vous voulez tout de suite?",
                    options: {
                        2: "donner des indices avant",
                        8: "dire directement, directement"
                    }
                },
                {
                    text: "Quel est le plus important pour vous?",
                    options: {
                        2: "obtenir de la reconnaissance",
                        8: "avoir de l'autonomie"
                    }
                },
                {
                    text: "Que pensez-vous est le pire?",
                    options: { 8: "flatterie", 2: "émoussé" }
                }
            ],

            3: [
                {
                    text:
                        "Protégez-vous les faibles aux dépens de votre propre succès ou choisiriez-vous quand même votre propre succès?",
                    options: { 8: "protéger les faibles", 3: "propre succès" }
                },
                {
                    text: "Ce qui est pire?",
                    options: { 3: "lenteur", 8: "injustice" }
                },
                {
                    text: "Que recherchez-vous de plus?",
                    options: {
                        8: "gagner en autonomie",
                        3: "gagner en reconnaissance"
                    }
                }
            ],

            4: [
                {
                    text:
                        "La (sur-)émotion est-elle pour vous une force ou une faiblesse?",
                    options: { 4: "une force", 8: "une faiblesse" }
                },
                {
                    text: "C'est quoi le pire?",
                    options: { 4: "insensible", 8: "injustice" }
                },
                {
                    text: "C'est quoi le pire?",
                    options: { 4: "être sans émotion", 8: "être sans pouvoir" }
                }
            ],

            5: [
                {
                    text: "C'est quoi le pire?",
                    options: { 8: "impuissance", 5: "ignorance" }
                },
                {
                    text:
                        "Les autres seraient-ils plus susceptibles de dire que vous les intimidez ou que vous êtes distant?",
                    options: { 8: "intimide", 5: "lointain" }
                },
                {
                    text:
                        "Les gens vous apprécient-ils le plus pour vos compétences analytiques ou pour votre esprit de décision?",
                    options: { 5: "capacité analytique", 8: "décision" }
                }
            ],

            6: [
                {
                    text: "Si vous n'êtes pas d'accord avec l'avis du groupe:",
                    options: {
                        6: "commencez par estimer le nombre de supporters que vous avez",
                        8: "donnerez-vous immédiatement votre avis de manière impulsive"
                    }
                },
                {
                    text: "À quoi aspirez-vous le plus?",
                    options: {
                        6: "acquérir la sécurité/sécurité",
                        8: "acquérir l'autonomie"
                    }
                },
                {
                    text: "Que faites-vous de plus d'habitude?",
                    options: { 8: "tirer le groupe", 6: "offrir un soutien" }
                }
            ],

            7: [
                {
                    text: "A quoi vous identifiez-vous le plus?",
                    options: {
                        7: "un papillon flottant",
                        8: "un éléphant puissant"
                    }
                },
                {
                    text: "Qu'est-ce qui est le plus important pour vous?",
                    options: { 7: "être heureux", 8: "être fort et puissant" }
                },
                {
                    text: "Qu'est-ce que tu détestes le plus?",
                    options: { 8: "malhonnêteté", 7: "ennui" }
                }
            ]
        },

        9: {
            2: [
                {
                    text: "C'est quoi le pire?",
                    options: { 9: "conflit", 2: "détachement" }
                },
                {
                    text: "C'est quoi le pire?",
                    options: {
                        2: "ne pas pouvoir aider",
                        9: "ne pas être vu/remarqué"
                    }
                },
                {
                    text:
                        "Attendez-vous que les gens viennent vous demander de l'aide ou cherchez-vous déjà des moyens d'aider sans même que d'autres le demandent?",
                    options: {
                        9: "viens vers toi et demande de l'aide",
                        2: "recherche déjà des moyens de t'aider"
                    }
                }
            ],

            3: [
                {
                    text: "Qu'est-ce qui est pire pour toi?",
                    options: { 9: "précipitation", 3: "lenteur" }
                },
                {
                    text: "Qu'est-ce qui vous convient le plus?",
                    options: { 9: "trop patient", 3: "trop impatient" }
                },
                {
                    text:
                        "Vous venez plutôt spontanément et sans problème au premier plan ou préférez-vous rester sur la touche sous les radars?",
                    options: { 3: "se mettre au premier plan", 9: "à l'écart" }
                }
            ],

            4: [
                {
                    text:
                        "Vous recherchez principalement la paix intérieure ou l'intensité dans votre vie affective?",
                    options: {
                        9: "paix intérieure",
                        4: "intensité dans ma vie intérieure"
                    }
                },
                {
                    text: "À quoi aspirez-vous le plus?",
                    options: {
                        4: "obtenir la reconnaissance de qui vous êtes",
                        9: "éviter les conflits"
                    }
                },
                {
                    text: "Que recherchez-vous le plus?",
                    options: { 4: "sens", 9: "cohérence" }
                }
            ],

            5: [
                {
                    text: "Sommes-nous tous un ou tous seuls?",
                    options: { 9: "tout un", 5: "tout seul" }
                },
                {
                    text: "Que recherchez-vous le plus?",
                    options: { 5: "indépendance", 9: "cohérence/harmonie" }
                },
                {
                    text:
                        "Est-il plus facile pour vous de concentrer votre attention ou vous égarez-vous facilement avec vos pensées?",
                    options: {
                        5: "concentrer l'attention",
                        9: "s'égarer facilement"
                    }
                }
            ],

            6: [
                {
                    text: "Quoi de plus important?",
                    options: {
                        6: "accomplir votre devoir",
                        9: "être satisfait"
                    }
                },
                {
                    text: "Que faites-vous le plus?",
                    options: {
                        9: "rechercher la paix intérieure",
                        6: "s'inquiéter"
                    }
                },
                {
                    text: "En cas de problème:",
                    options: {
                        6: "d'abord tout cartographier",
                        9: "vous espérez qu'ils partiront d'eux-mêmes"
                    }
                }
            ],

            7: [
                {
                    text: "Qu'est-ce qui vous convient le mieux?",
                    options: { 9: "patience", 7: "impatience" }
                },
                {
                    text: "Désirez-vous particulièrement:",
                    options: {
                        9: "la paix et l'harmonie",
                        7: "la joie et le plaisir"
                    }
                },
                {
                    text: "Qu'est-ce qui vous attire le plus?",
                    options: {
                        9: "routine productive",
                        7: "changer/quelque chose de nouveau"
                    }
                }
            ]
        },

        1: {
            2: [
                {
                    text: "Que trouvez-vous le plus difficile?",
                    options: {
                        1: "que vous n'êtes pas considéré comme assez précis",
                        2: "que vous n'êtes pas considéré comme assez utile"
                    }
                },
                {
                    text: "Que pensez-vous est le pire?",
                    options: { 1: "inconstance", 2: "la brutalité" }
                },
                {
                    text: "Quel est le plus important pour vous?",
                    options: {
                        1: "gagner en autonomie",
                        2: "gagner en reconnaissance"
                    }
                }
            ],

            3: [
                {
                    text: "Qu'est-ce qui vous dérange le plus?",
                    options: { 1: "nonchalance", 3: "lenteur" }
                },
                {
                    text: "A quoi vous identifiez-vous le plus?",
                    options: {
                        1: "avoir trop de votre propre opinion",
                        3: "s'adapter trop à ce que les gens importants attendent de vous"
                    }
                },
                {
                    text: "Qu'allez-vous faire en premier?",
                    options: { 1: "réussite du groupe", 3: "propre succès" }
                }
            ],

            4: [
                {
                    text: "Comment gérez-vous vos propres émotions?",
                    options: {
                        4: "aimez-vous en faire l'expérience au maximum",
                        1: "essayez-vous de le maîtriser plus tôt"
                    }
                },
                {
                    text:
                        "Que préféreriez-vous que les autres vous décrivent comme",
                    options: { 4: "original", 1: "rectiligne" }
                },
                {
                    text: "Que pensez-vous est le pire",
                    options: { 1: "nonchalance", 4: "inauthenticité" }
                }
            ],

            5: [
                {
                    text: "Qu'est-ce qui vous dérange le plus?",
                    options: { 5: "ignorance", 1: "paresse" }
                },
                {
                    text: "Quel est le plus important pour vous?",
                    options: {
                        1: "éviter les erreurs",
                        5: "éviter que les autres s'immiscent dans votre monde"
                    }
                },
                {
                    text: "À quoi aspirez-vous le plus?",
                    options: { 1: "autonomie", 5: "sécurité" }
                }
            ],

            6: [
                {
                    text: "Tombez plutôt dans:",
                    options: {
                        1: "rigidité<br/>(comme dans: être trop sûr de votre propre opinion)",
                        6: "la doute<br/>&nbsp;"
                    }
                },
                {
                    text: "À quoi aspirez-vous le plus?",
                    options: { 1: "autonomie", 6: "sécurité" }
                },
                {
                    text: "Qu'est-ce qui vous convient le mieux?",
                    options: {
                        1: "s'en tenir à sa propre opinion",
                        6: "prendre trop en compte l'opinion des autres"
                    }
                }
            ],

            7: [
                {
                    text: "Qu'est-ce qui vous convient le mieux?",
                    options: { 1: "responsabilité", 7: "liberté" }
                },
                {
                    text: "Qu'est-ce qui vous convient le mieux?",
                    options: {
                        1: "tout doit",
                        7: "tout est permis et rien ne doit"
                    }
                },
                {
                    text: "À quoi aspirez-vous le plus?",
                    options: {
                        1: "suivre les règles éthiques",
                        7: "faire l'expérience de la liberté"
                    }
                }
            ]
        },

        2: {
            5: [
                {
                    text:
                        "Avez-vous besoin de plus d'être seul ou surtout de vous amuser avec d'autres personnes?",
                    options: { 5: "être seul", 2: "être ensemble" }
                },
                {
                    text: "Si vous avez beaucoup de contacts avec les autres:",
                    options: {
                        2: "cela ne fait que vous donnez de l'énergie",
                        5: "en fait, cela absorbe de l'énergie de votre part"
                    }
                },
                {
                    text:
                        "Si quelqu'un vous approche émotionnellement, faites-vous:",
                    options: { 5: "plutôt s'éloigner", 2: "plutôt soutenir" }
                }
            ],

            6: [
                {
                    text: "Qu'est-ce qui vous frappe le plus?",
                    options: {
                        2: "son besoin",
                        6: "les incohérences qui apparaissent dans son histoire"
                    }
                },
                {
                    text: "Que se passe-t-il le plus?",
                    options: {
                        6: "que vous doutez et vous inquiétez",
                        2: "que vous aidez ou conseillez les autres sans quon vous le demande"
                    }
                },
                {
                    text:
                        "Sur quoi vous concentrez-vous lorsque vous faites partie d'un groupe?",
                    options: {
                        2: "aider les autres",
                        6: "accomplir ton devoir"
                    }
                }
            ],

            7: [
                {
                    text: "Qu'est-ce qui est pire pour toi",
                    options: {
                        2: "égoïsme",
                        7: "être coincé, n'avoir pas le choix"
                    }
                },
                {
                    text: "Qu'est-ce qui est le plus dur pour toi?",
                    options: { 2: "détachement", 7: "pessimisme" }
                },
                {
                    text: "Qu'est-ce qui vous attire le plus?",
                    options: { 7: "bons plans", 2: "peut vous aider" }
                }
            ]
        },

        3: {
            5: [
                {
                    text: "Où est votre endroit le plus aimé?",
                    options: { 3: "sur scène", 5: "arrière-scène" }
                },
                {
                    text: "Qu'est-ce qui vous fait le plus peur?",
                    options: {
                        3: "que vous échoueriez",
                        5: "que vous ne sauriez/compreniez pas quelque chose"
                    }
                },
                {
                    text: "Quel est le plus important?",
                    options: {
                        3: "être reconnu pour vos succès",
                        5: "être reconnu pour votre expertise"
                    }
                }
            ],

            6: [
                {
                    text: "Que pensez-vous est le pire?",
                    options: { 3: "lenteur", 6: "négligence" }
                },
                {
                    text: "Désirez-vous le plus:",
                    options: {
                        3: "reconnaissance de vos réalisations",
                        6: "la certitude que tout est bien préparé"
                    }
                },
                {
                    text: "Qu'est-ce qui vous convient le plus?",
                    options: { 6: "doute", 3: "résultat dès que possible" }
                }
            ],

            7: [
                {
                    text: "Qu'est-ce qui vous fait le plus peur",
                    options: {
                        3: "que vous échoueriez",
                        7: "que vous manqueriez une belle expérience"
                    }
                },
                {
                    text: "Qu'est-ce qui vous déclenche le plus?",
                    options: { 7: "aventure", 3: "compétition" }
                },
                {
                    text: "Qu'aimes-tu le plus?",
                    options: {
                        3: "atteindre mon objectif dès que possible",
                        7: "réfléchir sur les possibilités et les options"
                    }
                }
            ]
        },

        4: {
            5: [
                {
                    text: "Que préférez-vous de plus?",
                    options: {
                        4: "profondeur des émotions",
                        5: "profondeur de la connaissance"
                    }
                },
                {
                    text: "C'est quoi le pire?",
                    options: { 5: "émotivité manifeste", 4: "superficialité" }
                },
                {
                    text: "Qu'est-ce qui vous convient le mieux?",
                    options: {
                        4: "ressentir des émotions profondes et fortes et en parler avec les autres",
                        5: "éviter de telles conversations émotionnelles"
                    }
                }
            ],

            6: [
                {
                    text: "Êtes-vous plus susceptible de vous perdre dans:",
                    options: {
                        4: "le mal du pays et le désir ardent",
                        6: "les jours apocalyptiques dans ma tête"
                    }
                },
                {
                    text: "Qu'est-ce qui vous convient le mieux?",
                    options: { 4: "sens du but", 6: "sécurité" }
                },
                {
                    text: "Que veux-tu le plus?",
                    options: { 4: "trouver votre propre sens", 6: "certitude" }
                }
            ],

            7: [
                {
                    text: "Si vous deviez choisir, préféreriez-vous être:",
                    options: { 4: "malheureux", 7: "engourdi" }
                },
                {
                    text: "Que recherchez-vous le plus:",
                    options: {
                        4: "le sens de votre vie",
                        7: "de nouvelles expériences"
                    }
                },
                {
                    text: "Votre attention porte-t-elle principalement sur:",
                    options: { 4: "le passé", 7: "le futur" }
                }
            ]
        }
    }
};

export default texts;
