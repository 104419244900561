import { useState } from "react";
import Welcome from "./components/Welcome";
import RenderRound from "./components/RenderRound";
import Completed from "./components/Completed";
import { Header, Footer } from "./layout";
import { ProgressBar, Container } from "react-bootstrap";
import { LanguageType, AnswerType, ResultType } from "./types";
import "./sass/app.scss";

const App = () => {
  const language: LanguageType = "nl";
  const id = "dfhdgfhdgfhdfghdfg";

  const startResult: ResultType = {
    1: { type: 1, value: 0, center: false, order: 0 },
    2: { type: 2, value: 0, center: false, order: 0 },
    3: { type: 3, value: 0, center: false, order: 0 },
    4: { type: 4, value: 0, center: false, order: 0 },
    5: { type: 5, value: 0, center: false, order: 0 },
    6: { type: 6, value: 0, center: false, order: 0 },
    7: { type: 7, value: 0, center: false, order: 0 },
    8: { type: 8, value: 0, center: false, order: 0 },
    9: { type: 9, value: 0, center: false, order: 0 },
  };

  const [currentRound, setRound] = useState(0);
  const [competences, setCompetences] = useState<string[]>([]);
  const [progressCount, setProgressCount] = useState(83);
  const [result, setResult] = useState<ResultType>(startResult);
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [answers, setAnswers] = useState<AnswerType[]>([]);

  const finishRound = () => {
    const nextRound =
      currentRound === 6 && !competences.length ? 8 : currentRound + 1;
    setRound(nextRound);
    setCurrentQuestion(1);
  };

  const renderContent = () => {
    if (currentRound === 0) {
      return (
        <Welcome
          id={id}
          language={language}
          setRound={setRound}
          setCompetences={setCompetences}
          setResult={setResult}
          setCurrentQuestion={setCurrentQuestion}
          setProgressCount={setProgressCount}
        />
      );
    } else if (currentRound === 8) {
      return <Completed language={language} />;
    } else {
      return (
        <RenderRound
          id={id}
          language={language}
          currentRound={currentRound}
          result={result}
          answers={answers}
          competences={competences}
          setCurrentQuestion={setCurrentQuestion}
          currentQuestion={currentQuestion}
          setResult={setResult}
          setAnswers={setAnswers}
          finishCurrentRound={finishRound}
        />
      );
    }
  };

  const progress = (answers.length / progressCount) * 100;

  return (
    <>
      <div className="o-page">
        <div className="o-page__header">
          <Header />
        </div>
        <div className="o-page__main">{renderContent()}</div>
        <div className="o-page__footer">
          {currentRound > 0 && (
            <Container>
              <ProgressBar now={progress} className="mb-2" />
            </Container>
          )}
          <Footer />
        </div>
      </div>
    </>
  );
}

export default App;
