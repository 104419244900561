import { useState, useEffect } from "react";
import { Container, Button } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import {
    LanguageType,
    SaveAnswerDataType,
} from "../types";
import { Round7Texts } from "../languages/GetTexts";
import { Round7TextsType } from "../languages/types";
import questionSettings from "./round-7/questions";

type Round7Props = {
    currentQuestion: number;
    saveAnswer: (
        data: SaveAnswerDataType,
        result: null,
        finishRound: boolean
    ) => void;
    competences: string[];
    language: LanguageType;
};

type questionType = {
    competence_id: number;
    question: number;
    reverse: boolean;
    text?: string;
};

const Round7 = ({
    currentQuestion,
    saveAnswer,
    competences,
    language
}: Round7Props) => {
    const [first, setFirst] = useState(true);
    const [active, setActive] = useState(0);
    const [questions, setQuestions] = useState<questionType[]>(
        questionSettings
    );
    const texts: Round7TextsType = Round7Texts(language);

    useEffect(() => {
      const random: questionType[] = questionSettings
        .filter((i) => competences.includes(i.competence_id.toString()))
        .sort(() => 0.5 - Math.random());
      setQuestions(random);
    }, [competences]);

    const saveQuestion = () => {
        setActive(0);
        const question: questionType = questions[currentQuestion - 1];
        question.text =
            texts.questions[questions[currentQuestion - 1].question];
        const completed = currentQuestion === questions.length;
        const value = question.reverse ? 5 - active : active - 1;
        saveAnswer({ extra: question, answer: value }, null, completed);
    };

    const renderButtons = () => {
        const options = [1, 2, 3, 4, 5];

        return options.map(i => (
            <Button
                variant={active === i ? "secondary" : "primary"}
                className="mr-1"
                size="lg"
                onClick={() => setActive(i)}
            >
                {i}
            </Button>
        ));
    };

    return (
        <>
            {first ? (
                <div className="m-content">
                    <h2>{ReactHtmlParser(texts.explanation)}</h2>
                </div>
            ) : (
                <div className="m-content">
                    <Container>
                        <div className="a-quote">
                            <h2 className="-quote">
                                {ReactHtmlParser(
                                    texts.questions[
                                        questions[currentQuestion - 1].question
                                    ]
                                )}
                            </h2>
                        </div>
                        <div className="mt-4 mb-1">
                            {texts.scale_left}
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {texts.scale_right}&nbsp;&nbsp;&nbsp;
                        </div>

                        <div>{renderButtons()}</div>
                    </Container>
                </div>
            )}
            <div className="m-actions">
                {first && (
                    <Button onClick={() => setFirst(false)} size="lg">
                        {texts.button_start}
                    </Button>
                )}
                {active !== 0 && (
                    <Button size="lg" onClick={() => saveQuestion()}>
                        {texts.button_next}
                    </Button>
                )}
            </div>
        </>
    );
};

export default Round7;
