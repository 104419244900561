const texts = {
    explanation: `You will see a set of 3 images and words.<br/>
    Click on these images in the order in which they appeal to you the most.`,
    button_start: 'Go to the images',
    button_next: 'Next set',
    button_again: 'Reset',
    text_1: `appeals to me  <span className="important">the most</span>`,
    text_3: `appeals to me <span className="important">the least</span>`,
};

export default texts;
