const texts = {
    title: 'ANALYSE DE PERSONNALITÉ',
    description: `L'intention est que vous indiquiez toujours dans quelle mesure les déclarations que vous voyez vous concernent le plus.
    <br />
    Complétez le test en fonction de votre opinion et de votre situation MAINTENANT dans un contexte professionnel.
    <br />
    <br />
    Merci et bonne chance!`,
    privacy_first: 'J\'ai lu et j\'accepte',
    privacy_link: 'la politique de confidentialité',
    privacy_last: '',
    start: 'Commencer le test'
};

export default texts;
