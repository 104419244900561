import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from 'apollo-link-context';

const paths = {
  development: 'http://localhost:8000/graphql',
  production: 'https://api.cowisoft.be/graphql',
}

const tenantLink = setContext((_, { headers }) => {

  const tenantId = localStorage.getItem('tenantId')
  let token = RegExp('XSRF-TOKEN[^;]+').exec(document.cookie)
  token = decodeURIComponent(token ? token.toString().replace(/^[^=]+./, '') : '')

  return {
    headers: {
      ...headers,
      "X-Tenant": tenantId,
      "X-XSRF-TOKEN": token,
    },
  };
});

const ClientLink = createHttpLink({
  uri: paths[process.env.NODE_ENV],
  credentials: "include",
});

const client= new ApolloClient({
  link: tenantLink.concat(ClientLink),
  cache: new InMemoryCache(),
});

export default client;
