const questions = [
    {
        competence_id: 1,
        question: 1,
        reverse: false
    },
    {
        competence_id: 1,
        question: 2,
        reverse: false
    },
    {
        competence_id: 1,
        question: 3,
        reverse: false
    },
    {
        competence_id: 1,
        question: 4,
        reverse: true
    },
    {
        competence_id: 1,
        question: 5,
        reverse: true
    },

    {
        competence_id: 2,
        question: 6,
        reverse: false
    },
    {
        competence_id: 2,
        question: 7,
        reverse: false
    },
    {
        competence_id: 2,
        question: 8,
        reverse: false
    },
    {
        competence_id: 2,
        question: 9,
        reverse: true
    },
    {
        competence_id: 2,
        question: 10,
        reverse: true
    },

    {
        competence_id: 3,
        question: 11,
        reverse: false
    },
    {
        competence_id: 3,
        question: 12,
        reverse: false
    },
    {
        competence_id: 3,
        question: 13,
        reverse: false
    },
    {
        competence_id: 3,
        question: 14,
        reverse: true
    },
    {
        competence_id: 3,
        question: 15,
        reverse: true
    },

    {
        competence_id: 4,
        question: 16,
        reverse: false
    },
    {
        competence_id: 4,
        question: 17,
        reverse: false
    },
    {
        competence_id: 4,
        question: 18,
        reverse: false
    },
    {
        competence_id: 4,
        question: 19,
        reverse: true
    },
    {
        competence_id: 4,
        question: 20,
        reverse: true
    },

    {
        competence_id: 5,
        question: 21,
        reverse: false
    },
    {
        competence_id: 5,
        question: 22,
        reverse: false
    },
    {
        competence_id: 5,
        question: 23,
        reverse: false
    },
    {
        competence_id: 5,
        question: 24,
        reverse: true
    },
    {
        competence_id: 5,
        question: 25,
        reverse: true
    },

    {
        competence_id: 6,
        question: 26,
        reverse: false
    },
    {
        competence_id: 6,
        question: 27,
        reverse: false
    },
    {
        competence_id: 6,
        question: 28,
        reverse: false
    },
    {
        competence_id: 6,
        question: 29,
        reverse: true
    },
    {
        competence_id: 6,
        question: 30,
        reverse: true
    },

    {
        competence_id: 7,
        question: 31,
        reverse: false
    },
    {
        competence_id: 7,
        question: 32,
        reverse: false
    },
    {
        competence_id: 7,
        question: 33,
        reverse: false
    },
    {
        competence_id: 7,
        question: 34,
        reverse: true
    },
    {
        competence_id: 7,
        question: 35,
        reverse: true
    },

    {
        competence_id: 8,
        question: 36,
        reverse: false
    },
    {
        competence_id: 8,
        question: 37,
        reverse: false
    },
    {
        competence_id: 8,
        question: 38,
        reverse: false
    },
    {
        competence_id: 8,
        question: 39,
        reverse: true
    },
    {
        competence_id: 8,
        question: 40,
        reverse: true
    },

    {
        competence_id: 9,
        question: 41,
        reverse: false
    },
    {
        competence_id: 9,
        question: 42,
        reverse: false
    },
    {
        competence_id: 9,
        question: 43,
        reverse: false
    },
    {
        competence_id: 9,
        question: 44,
        reverse: true
    },
    {
        competence_id: 9,
        question: 45,
        reverse: true
    },

    {
        competence_id: 10,
        question: 46,
        reverse: false
    },
    {
        competence_id: 10,
        question: 47,
        reverse: false
    },
    {
        competence_id: 10,
        question: 48,
        reverse: false
    },
    {
        competence_id: 10,
        question: 49,
        reverse: true
    },
    {
        competence_id: 10,
        question: 50,
        reverse: true
    },

    {
        competence_id: 11,
        question: 51,
        reverse: false
    },
    {
        competence_id: 11,
        question: 52,
        reverse: false
    },
    {
        competence_id: 11,
        question: 53,
        reverse: false
    },
    {
        competence_id: 11,
        question: 54,
        reverse: true
    },
    {
        competence_id: 11,
        question: 55,
        reverse: true
    },

    {
        competence_id: 12,
        question: 56,
        reverse: false
    },
    {
        competence_id: 12,
        question: 57,
        reverse: false
    },
    {
        competence_id: 12,
        question: 58,
        reverse: false
    },
    {
        competence_id: 12,
        question: 59,
        reverse: true
    },
    {
        competence_id: 12,
        question: 60,
        reverse: true
    },

    {
        competence_id: 13,
        question: 61,
        reverse: false
    },
    {
        competence_id: 13,
        question: 62,
        reverse: false
    },
    {
        competence_id: 13,
        question: 63,
        reverse: false
    },
    {
        competence_id: 13,
        question: 64,
        reverse: true
    },
    {
        competence_id: 13,
        question: 65,
        reverse: true
    },

    {
        competence_id: 14,
        question: 66,
        reverse: false
    },
    {
        competence_id: 14,
        question: 67,
        reverse: false
    },
    {
        competence_id: 14,
        question: 68,
        reverse: false
    },
    {
        competence_id: 14,
        question: 69,
        reverse: true
    },
    {
        competence_id: 14,
        question: 70,
        reverse: true
    },

    {
        competence_id: 15,
        question: 71,
        reverse: false
    },
    {
        competence_id: 15,
        question: 72,
        reverse: false
    },
    {
        competence_id: 15,
        question: 73,
        reverse: false
    },
    {
        competence_id: 15,
        question: 74,
        reverse: true
    },
    {
        competence_id: 15,
        question: 75,
        reverse: true
    },

    {
        competence_id: 16,
        question: 76,
        reverse: false
    },
    {
        competence_id: 16,
        question: 77,
        reverse: false
    },
    {
        competence_id: 16,
        question: 78,
        reverse: false
    },
    {
        competence_id: 16,
        question: 79,
        reverse: true
    },
    {
        competence_id: 16,
        question: 80,
        reverse: true
    },

    {
        competence_id: 17,
        question: 81,
        reverse: false
    },
    {
        competence_id: 17,
        question: 82,
        reverse: false
    },
    {
        competence_id: 17,
        question: 83,
        reverse: false
    },
    {
        competence_id: 17,
        question: 84,
        reverse: true
    },
    {
        competence_id: 17,
        question: 85,
        reverse: true
    },

    {
        competence_id: 18,
        question: 86,
        reverse: false
    },
    {
        competence_id: 18,
        question: 87,
        reverse: false
    },
    {
        competence_id: 18,
        question: 88,
        reverse: false
    },
    {
        competence_id: 18,
        question: 89,
        reverse: true
    },
    {
        competence_id: 18,
        question: 90,
        reverse: true
    },

    {
        competence_id: 19,
        question: 91,
        reverse: false
    },
    {
        competence_id: 19,
        question: 92,
        reverse: false
    },
    {
        competence_id: 19,
        question: 93,
        reverse: false
    },
    {
        competence_id: 19,
        question: 94,
        reverse: true
    },
    {
        competence_id: 19,
        question: 95,
        reverse: true
    },

    {
        competence_id: 20,
        question: 96,
        reverse: false
    },
    {
        competence_id: 20,
        question: 97,
        reverse: false
    },
    {
        competence_id: 20,
        question: 98,
        reverse: false
    },
    {
        competence_id: 20,
        question: 99,
        reverse: true
    },
    {
        competence_id: 20,
        question: 100,
        reverse: true
    },

    {
        competence_id: 21,
        question: 101,
        reverse: false
    },
    {
        competence_id: 21,
        question: 102,
        reverse: false
    },
    {
        competence_id: 21,
        question: 103,
        reverse: false
    },
    {
        competence_id: 21,
        question: 104,
        reverse: true
    },
    {
        competence_id: 21,
        question: 105,
        reverse: true
    },

    {
        competence_id: 22,
        question: 106,
        reverse: false
    },
    {
        competence_id: 22,
        question: 107,
        reverse: false
    },
    {
        competence_id: 22,
        question: 108,
        reverse: false
    },
    {
        competence_id: 22,
        question: 109,
        reverse: true
    },
    {
        competence_id: 22,
        question: 110,
        reverse: true
    },

    {
        competence_id: 23,
        question: 111,
        reverse: false
    },
    {
        competence_id: 23,
        question: 112,
        reverse: false
    },
    {
        competence_id: 23,
        question: 113,
        reverse: false
    },
    {
        competence_id: 23,
        question: 114,
        reverse: true
    },
    {
        competence_id: 23,
        question: 115,
        reverse: true
    },

    {
        competence_id: 24,
        question: 116,
        reverse: false
    },
    {
        competence_id: 24,
        question: 117,
        reverse: false
    },
    {
        competence_id: 24,
        question: 118,
        reverse: false
    },
    {
        competence_id: 24,
        question: 119,
        reverse: true
    },
    {
        competence_id: 24,
        question: 120,
        reverse: true
    },

    {
        competence_id: 25,
        question: 121,
        reverse: false
    },
    {
        competence_id: 25,
        question: 122,
        reverse: false
    },
    {
        competence_id: 25,
        question: 123,
        reverse: false
    },
    {
        competence_id: 25,
        question: 124,
        reverse: true
    },
    {
        competence_id: 25,
        question: 125,
        reverse: true
    },

    {
        competence_id: 26,
        question: 126,
        reverse: false
    },
    {
        competence_id: 26,
        question: 127,
        reverse: false
    },
    {
        competence_id: 26,
        question: 128,
        reverse: false
    },
    {
        competence_id: 26,
        question: 129,
        reverse: true
    },
    {
        competence_id: 26,
        question: 130,
        reverse: true
    },

    {
        competence_id: 27,
        question: 131,
        reverse: false
    },
    {
        competence_id: 27,
        question: 132,
        reverse: false
    },
    {
        competence_id: 27,
        question: 133,
        reverse: false
    },
    {
        competence_id: 27,
        question: 134,
        reverse: true
    },
    {
        competence_id: 27,
        question: 135,
        reverse: true
    },

    {
        competence_id: 28,
        question: 136,
        reverse: false
    },
    {
        competence_id: 28,
        question: 137,
        reverse: false
    },
    {
        competence_id: 28,
        question: 138,
        reverse: false
    },
    {
        competence_id: 28,
        question: 139,
        reverse: true
    },
    {
        competence_id: 28,
        question: 140,
        reverse: true
    },

    {
        competence_id: 29,
        question: 141,
        reverse: false
    },
    {
        competence_id: 29,
        question: 142,
        reverse: false
    },
    {
        competence_id: 29,
        question: 143,
        reverse: false
    },
    {
        competence_id: 29,
        question: 144,
        reverse: true
    },
    {
        competence_id: 29,
        question: 145,
        reverse: true
    },

    {
        competence_id: 30,
        question: 146,
        reverse: false
    },
    {
        competence_id: 30,
        question: 147,
        reverse: false
    },
    {
        competence_id: 30,
        question: 148,
        reverse: false
    },
    {
        competence_id: 30,
        question: 149,
        reverse: true
    },
    {
        competence_id: 30,
        question: 150,
        reverse: true
    },

    {
        competence_id: 31,
        question: 151,
        reverse: false
    },
    {
        competence_id: 31,
        question: 152,
        reverse: false
    },
    {
        competence_id: 31,
        question: 153,
        reverse: false
    },
    {
        competence_id: 31,
        question: 154,
        reverse: true
    },
    {
        competence_id: 31,
        question: 155,
        reverse: true
    },

    {
        competence_id: 32,
        question: 156,
        reverse: false
    },
    {
        competence_id: 32,
        question: 157,
        reverse: false
    },
    {
        competence_id: 32,
        question: 158,
        reverse: false
    },
    {
        competence_id: 32,
        question: 159,
        reverse: true
    },
    {
        competence_id: 32,
        question: 160,
        reverse: true
    },

    {
        competence_id: 33,
        question: 161,
        reverse: false
    },
    {
        competence_id: 33,
        question: 162,
        reverse: false
    },
    {
        competence_id: 33,
        question: 163,
        reverse: false
    },
    {
        competence_id: 33,
        question: 164,
        reverse: true
    },
    {
        competence_id: 33,
        question: 165,
        reverse: true
    },

    {
        competence_id: 34,
        question: 166,
        reverse: false
    },
    {
        competence_id: 34,
        question: 167,
        reverse: false
    },
    {
        competence_id: 34,
        question: 168,
        reverse: false
    },
    {
        competence_id: 34,
        question: 169,
        reverse: true
    },
    {
        competence_id: 34,
        question: 170,
        reverse: true
    },

    {
        competence_id: 35,
        question: 171,
        reverse: false
    },
    {
        competence_id: 35,
        question: 172,
        reverse: false
    },
    {
        competence_id: 35,
        question: 173,
        reverse: false
    },
    {
        competence_id: 35,
        question: 174,
        reverse: true
    },
    {
        competence_id: 35,
        question: 175,
        reverse: true
    },

    {
        competence_id: 36,
        question: 176,
        reverse: false
    },
    {
        competence_id: 36,
        question: 177,
        reverse: false
    },
    {
        competence_id: 36,
        question: 178,
        reverse: false
    },
    {
        competence_id: 36,
        question: 179,
        reverse: true
    },
    {
        competence_id: 36,
        question: 180,
        reverse: true
    }
];

export default questions;
