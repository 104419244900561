const texts = {
    explanation: `Vous recevrez désormais un relevé à chaque fois.<br />
    Indiquez de 1 à 5 dans la mesure dans laquelle cette déclaration s'applique à vous.<br />
    1 s'applique le moins et 5 s'applique le plus.<br />`,
    scale_left: "Pas du tout d'accord",
    scale_right: "Entièrement d'accord",
    button_next: "Suivante",
    button_start: "Continuer avec les déclarations",
    questions: [
        {
            question: 1,
            text: "Je vais le faire moi-même, alors je sais que c'est bien fait."
        },
        {
            question: 2,
            text:
                "Je déteste commettre des erreurs, je ne suis vraiment pas bon à ça. Après tout, je suis très critique et je vérifie tout avant de considérer les choses comme « fini »."
        },
        {
            question: 3,
            text:
                " Les règles sont là à suivre.<br/>Il n'est pas juste d'enfreindre les règles. Vous ne le faites pas!"
        },
        {
            question: 4,
            text:
                "Je ne comprends pas pourquoi tant de gens ont des normes aussi laxistes."
        },
        {
            question: 5,
            text:
                "J'ai du mal à ne pas vouloir me mêler de tout, pour que ce soit bien fait"
        },
        {
            question: 6,
            text:
                "La plupart des gens me voient comme sérieux...<br /> Je suis généralement perçu de cette façon."
        },

        {
            question: 7,
            text:
                "C'est difficile pour moi de dire non parce qu'alors je pense que<br/>je décevoir les gens et j'évite ça."
        },
        {
            question: 8,
            text:
                "Je m'occupe d'abord des autres. Après tout, je suis là pour aider les autres."
        },
        {
            question: 9,
            text:
                "Si je ne ressens pas de connexion, je ne fonctionne pas correctement.<br/>J'aime être le meilleur ami des autres et j'ai donc tendance à plaire."
        },
        {
            question: 10,
            text:
                "Même si les gens me sont indifférents, je vais tout faire pour que ces gens m'aiment."
        },
        {
            question: 11,
            text:
                "Si je vois un chien errant, je préférerais le ramener à la maison immédiatement."
        },
        {
            question: 12,
            text:
                "Je n'aime pas m'attribuer le mérite des nombreuses choses que je fais pour les autres, mais cela peut me ronger ou même me déranger s'ils ne le remarquent pas où s'en moquent."
        },

        {
            question: 13,
            text:
                "Ce n'est pas bien de ne pas être bien.<br/>C'est pourquoi je donne toujours l'impression que tout va bien."
        },
        { question: 14, text: "Echouer n'est pas une option! Je veux toujours gagner." },
        {
            question: 15,
            text:
                " J'ai un objectif clair et je dois l'atteindre le plus efficacement<br/>(et de préférence le plus rapidement) possible."
        },
        {
            question: 16,
            text:
                "Je me considère comme compétent:<br/>je ne me sens pas bien si je ne suis pas efficace et efficient."
        },
        {
            question: 17,
            text:
                "Il est important pour moi que je puisse avoir l'impression de réussir,<br/>même si je n'ai pas encore le grand succès pour lequel je m'efforce."
        },
        {
            question: 18,
            text:
                "Je ressemble à un bourreau de travail, je me sens inutile et agité lorsque je n'essaie pas d'accomplir quelque chose."
        },

        {
            question: 19,
            text:
                "Tout ce que je fais doit avoir une signification.<br/>J'aspire à la profondeur en tout."
        },
        {
            question: 20,
            text: "Les autres ne me comprennent pas. Je me sens souvent incompris."
        },
        {
            question: 21,
            text:
                "Je ne veux pas être une souris grise ; Je veux vraiment faire la différence!"
        },
        {
            question: 22,
            text:
                "Beaucoup de gens me trouvent énigmatique,<br/>difficile et contradictoire – en fait j'aime ça."
        },
        {
            question: 23,
            text:
                "Apprendre à me connaître et être fidèle à mes besoins émotionnels ont toujours été des moteurs importants pour moi."
        },
        {
            question: 24,
            text:
                "Quand je suis critiqué ou incompris, j'ai tendance à me retirer et même à bouder."
        },

        {
            question: 25,
            text: "Je préfère séparer ma vie privée de mon travail."
        },
        {
            question: 26,
            text:
                "La compréhension des choses est importante pour moi ; Je veux « savoir » et je chercherai jusqu'à ce que j'aie plus de connaissances ; vous ne pouvez jamais en savoir assez."
        },
        {
            question: 27,
            text:
                "Je peux me concentrer pendant des heures, voire des jours sur le sujet qui m'intéresse. Je peux facilement être seul pendant longtemps."
        },
        {
            question: 28,
            text:
                "Ce n'est que sur la base d'informations précises que l'on peut prendre une décision rationnelle. Cependant, la plupart des gens ne sont pas très rationnels."
        },
        {
            question: 29,
            text:
                "La plupart des gens sont si incroyablement stupides que<br/>c'est presque un miracle que tout se passe bien."
        },
        {
            question: 30,
            text:
                "Je suis très curieux et j'aime explorer pourquoi les choses sont comme elles sont. Même les choses les plus évidentes ne sont pas si évidentes lorsque vous les étudiez réellement."
        },
        {
            question: 31,
            text:
                " J'ai un grand sens des responsabilités et je pense<br/>qu'il est très important de travailler ensemble dans la confiance."
        },
        { question: 32, text: "Je m'angoisse assez facilement et j'ai souvent des doutes." },
        {
            question: 33,
            text:
                "J'aime être prêt à tout et ne me sens à l'aise que lorsque<br/>je sais où je me situe et que tout est clair. J'aime être préparé à l'avance pour les choses ou les situations possibles."
        },
        {
            question: 34,
            text:
                "L'autorité m'attire. En même temps, je m'en méfie."
        },
        {
            question: 35,
            text:
                " Je me sens plus en confiance si je sais clairement ce que<br/>l'on attend de moi que si je travaillais seul."
        },
        {
            question: 36,
            text:
                "Avant de prendre une décision importante, je vérifie auprès de personnes en qui j'ai confiance avant de prendre une décision efficace. Habituellement, je sais à l'avance quelle serait ma décision, mais ça fait du bien d'avoir une confirmation."
        },

        {
            question: 37,
            text: "La structure et la routine sont ennuyeuses, alors je les déteste."
        },
        {
            question: 38,
            text:
                "Ma tête est pleine d'idées;<br/>Je suis très doué pour réfléchir à des choses amusantes et/ou nouvelles."
        },
        {
            question: 39,
            text:
                "Je veux être partout et ne pas manquer des choses et/ou des expériences amusantes. Imaginez si je ratais quelque chose d'amusant! Je détesterais vraiment ça."
        },
        {
            question: 40,
            text:
                "J'aime voyager et l'aventure et j'aime découvrir d'autres cultures et acquérir de nouvelles expériences. Après tout, le monde est une fantastique source de nouveaux stimuli."
        },
        {
            question: 41,
            text:
                "D'habitude j'ai un agenda chargé et je préfère le garder<br/>comme ça parce que je ne veux pas m'ennuyer."
        },
        {
            question: 42,
            text:
                "Les détails ne sont pas pour moi. Je me sens mieux dans l'ensemble. Après tout, il est bien plus amusant de réfléchir à de nouvelles idées que de participer à leur mise en œuvre."
        },

        { question: 43, text: "Personne ne décide pour moi ; c'est ma vie!" },
        { question: 44, text: "Ne te montre pas faible, continue!" },
        {
            question: 45,
            text:
                "C’est moi qui décide.<br/>Quand les gens essaient de me commander, je ressens une résistance. Garder le contrôle de mes propres actions est important – j'aime avoir le contrôle!"
        },
        {
            question: 46,
            text:
                "Je ne peux pas sympathiser avec les faibles et les personnes qui n'osent pas immédiatement exprimer leur propre opinion. La faiblesse ne cause vraiment que des problèmes."
        },
        {
            question: 47,
            text:
                "Dans cette situation, je suis vraiment très fier: lorsque les personnes que j'ai eues sous mon aile grandissent en tant que personnes et réussissent dans les affaires ou dans leur travail."
        },
        {
            question: 48,
            text:
                "J'éprouve un immense sentiment d'accomplissement lorsque je fais quelque chose que les autres considèrent comme impossible. J'aime marcher sur le bord et voir que, contre toute attente, je réussis."
        },

        {
            question: 49,
            text:
                "Je suis une personne qui suit le courant;<br/>J'évite les conflits et préfère rester sous le radar."
        },
        {
            question: 50,
            text:
                "Vous ne pouvez pas me presser ; Je ne me précipite pas et je fais tout à mon rythme. Je procrastine parfois, mais lorsqu'une échéance approche, je l'accélère d'un cran."
        },
        {
            question: 51,
            text:
                "Je suis heureux et satisfait lorsque les gens autour de moi sont heureux et satisfaits. Cela me donne la paix."
        },
        {
            question: 52,
            text: "Je préfère donner à quelqu'un ce qu'il veut plutôt que de faire une scène."
        },
        {
            question: 53,
            text:
                "Les gens disent parfois que je semble absent ou distrait. Le fait est que je comprends très bien les choses mais que je ne veux tout simplement pas y réagir parce que je ne veux pas troubler ma paix intérieure."
        },
        {
            question: 54,
            text:
                "Il faut se résigner à ce que la vie apporte.<br/>Vous ne pouvez pas y faire grand-chose de toute façon."
        }
    ]
};

export default texts;
