import { LanguageType } from "../types";
import { welcome_nl, round1_nl, round2_nl, round3_nl, round4_nl, round5_nl, round6_nl, round7_nl, completed_nl } from "./nl";
import { welcome_fr, round1_fr, round2_fr, round3_fr, round4_fr, round5_fr, round6_fr, round7_fr, completed_fr } from "./fr";
import { welcome_en, round1_en, round2_en, round3_en, round4_en, round5_en, round6_en, round7_en, completed_en } from "./en";
import { WelcomeTextsType, Round1TextsType, Round2TextsType, Round3TextsType, Round4TextsType, Round5TextsType, Round6TextsType, Round7TextsType, CompletedTextsType } from './types';

const languages = {
    nl: {
        welcome: welcome_nl,
        round1: round1_nl,
        round2: round2_nl,
        round3: round3_nl,
        round4: round4_nl,
        round5: round5_nl,
        round6: round6_nl,
        round7: round7_nl,
        completed: completed_nl
    },
    en: {
        welcome: welcome_en,
        round1: round1_en,
        round2: round2_en,
        round3: round3_en,
        round4: round4_en,
        round5: round5_en,
        round6: round6_en,
        round7: round7_en,
        completed: completed_en
    },
    fr: {
        welcome: welcome_fr,
        round1: round1_fr,
        round2: round2_fr,
        round3: round3_fr,
        round4: round4_fr,
        round5: round5_fr,
        round6: round6_fr,
        round7: round7_fr,
        completed: completed_fr
    }
};


export const WelcomeTexts = (language: LanguageType): WelcomeTextsType => {
    return languages[language].welcome;
}

export const Round1Texts = (language: LanguageType): Round1TextsType => {
    return languages[language].round1;
}

export const Round2Texts = (language: LanguageType): Round2TextsType => {
    return languages[language].round2;
}

export const Round3Texts = (language: LanguageType): Round3TextsType => {
    return languages[language].round3;
}

export const Round4Texts = (language: LanguageType): Round4TextsType => {
    return languages[language].round4;
}

export const Round5Texts = (language: LanguageType): Round5TextsType => {
    return languages[language].round5;
}

export const Round6Texts = (language: LanguageType): Round6TextsType => {
    return languages[language].round6;
}

export const Round7Texts = (language: LanguageType): Round7TextsType => {
    return languages[language].round7;
}

export const CompletedTexts = (language: LanguageType): CompletedTextsType => {
    return languages[language].completed;
}
