const texts = {
    button_next: "Next statement",

    questions: {
        8: {
            2: [
                {
                    text:
                        "Are you more likely to give hints when you want something or do you just say what you want straight away?",
                    options: { 2: "to give hints before", 8: "to say straight" }
                },
                {
                    text: "What is most important to you?",
                    options: { 2: "gain recognition", 8: "have autonomy" }
                },
                {
                    text: "What do you think is the worst?",
                    options: { 8: "flattery", 2: "blunt" }
                }
            ],

            3: [
                {
                    text:
                        "Would you protect the weak at the expense of your own success or would you choose your own success anyway?",
                    options: { 8: "protect the weak", 3: "own success" }
                },
                {
                    text: "What's worse?",
                    options: { 3: "slowness", 8: "injustice" }
                },
                {
                    text: "What do you strive for more?",
                    options: { 8: "gain autonomy", 3: "gain recognition" }
                }
            ],

            4: [
                {
                    text:
                        "Is (over-)emotionality for you a strength or a weakness?",
                    options: { 4: "a strength", 8: "a weakness" }
                },
                {
                    text: "What's the worst?",
                    options: { 4: "insensitive", 8: "injustice" }
                },
                {
                    text: "What's the worst?",
                    options: { 4: "being emotionless", 8: "being powerless" }
                }
            ],

            5: [
                {
                    text: "What's the worst?",
                    options: { 8: "powerlessness", 5: "ignorance" }
                },
                {
                    text:
                        "Would the others be more likely to say that you are intimidating them or that you are aloof?",
                    options: { 8: "intimidates", 5: "distant" }
                },
                {
                    text:
                        "Do people value you most for your analytical skills or for your decisiveness?",
                    options: { 5: "analytical ability", 8: "decisiveness" }
                }
            ],

            6: [
                {
                    text: "If you do not agree with the opinion of the group:",
                    options: {
                        6: "first start by estimating how many supporters you have",
                        8: "will you immediately give your opinion impulsively"
                    }
                },
                {
                    text: "What do you strive for most?",
                    options: {
                        6: "acquiring security/security",
                        8: "acquiring autonomy"
                    }
                },
                {
                    text: "What do you usually do more?",
                    options: { 8: "pulling the group", 6: "providing support" }
                }
            ],

            7: [
                {
                    text: "What do you most identify with?",
                    options: {
                        7: "a fluttering butterfly",
                        8: "a strong elephant"
                    }
                },
                {
                    text: "What is more important to you?",
                    options: {
                        7: "to be happy",
                        8: "to be strong and powerful"
                    }
                },
                {
                    text: "What do you hate the most?",
                    options: { 8: "dishonesty", 7: "dullness" }
                }
            ]
        },

        9: {
            2: [
                {
                    text: "What's the worst?",
                    options: { 9: "conflict", 2: "detachment" }
                },
                {
                    text: "What's the worst?",
                    options: {
                        2: "not being able to help",
                        9: "not being seen/notice"
                    }
                },
                {
                    text:
                        "Do you wait for people to come to you and ask for help or are you already looking for ways to help without others even asking?",
                    options: {
                        9: "come to you and ask for help",
                        2: "already look for ways to help yourself"
                    }
                }
            ],

            3: [
                {
                    text: "What's worst for you?",
                    options: { 9: "hurriedness", 3: "sluggishness" }
                },
                {
                    text: "What suits you most?",
                    options: { 9: "too patient", 3: "too impatient" }
                },
                {
                    text:
                        "Do you rather spontaneously and without problem come to the forefront or do you prefer to stay on the sidelines under the radar?",
                    options: { 3: "to come to the fore", 9: "to the sidelines" }
                }
            ],

            4: [
                {
                    text:
                        "Are you mainly looking for inner peace or intensity in your emotional life?",
                    options: {
                        9: "inner peace",
                        4: "intensity in my inner life"
                    }
                },
                {
                    text: "What do you strive for most?",
                    options: {
                        4: "gain recognition for who you are",
                        9: "avoid conflict"
                    }
                },
                {
                    text: "What are you most looking for?",
                    options: { 4: "meaning", 9: "coherence" }
                }
            ],

            5: [
                {
                    text: "Are we all one or all alone?",
                    options: { 9: "all one", 5: "all alone" }
                },
                {
                    text: "What are you most looking for?",
                    options: { 5: "independence", 9: "coherence/harmony" }
                },
                {
                    text:
                        "Is it easier for you to focus your attention or do you easily wander with your thoughts?",
                    options: { 5: "focus attention", 9: "stray easily" }
                }
            ],

            6: [
                {
                    text: "What's more important?",
                    options: { 6: "fulfilling your duty", 9: "being content" }
                },
                {
                    text: "What do you do most?",
                    options: { 9: "striving for inner peace", 6: "worrying" }
                },
                {
                    text: "In case of problems:",
                    options: {
                        6: "first map everything out",
                        9: "hope that they will go away on their own"
                    }
                }
            ],

            7: [
                {
                    text: "What suits you best?",
                    options: { 9: "patience", 7: "impatience" }
                },
                {
                    text: "Do you especially want:",
                    options: { 9: "peace and harmony", 7: "joy and pleasure" }
                },
                {
                    text: "What appeals to you most?",
                    options: {
                        9: "productive routine",
                        7: "change/something new"
                    }
                }
            ]
        },

        1: {
            2: [
                {
                    text: "What do you find most difficult?",
                    options: {
                        1: "that you are not considered accurate enough",
                        2: "that you are not considered helpful enough"
                    }
                },
                {
                    text: "What do you think is the worst?",
                    options: { 1: "fickleness", 2: "bluntness" }
                },
                {
                    text: "What is most important to you?",
                    options: { 1: "gain autonomy", 2: "gain recognition" }
                }
            ],

            3: [
                {
                    text: "What bothers you the most?",
                    options: { 1: "nonchalance", 3: "slowness" }
                },
                {
                    text: "What do you most identify with?",
                    options: {
                        1: "holding too much of your own opinion",
                        3: "adapting too much to what important people expect of you"
                    }
                },
                {
                    text: "What are you going for first?",
                    options: { 1: "group success", 3: "own success" }
                }
            ],

            4: [
                {
                    text: "How do you deal with your own emotions?",
                    options: {
                        4: "do you like to experience this to the fullest",
                        1: "do you try to master it sooner"
                    }
                },
                {
                    text: "What would you prefer that others describe you as",
                    options: { 4: "original", 1: "straightline" }
                },
                {
                    text: "What do you think is the worst",
                    options: { 1: "nonchalance", 4: "inauthenticity" }
                }
            ],

            5: [
                {
                    text: "What bothers you the most?",
                    options: { 5: "ignorance", 1: "laziness" }
                },
                {
                    text: "What is most important to you?",
                    options: {
                        1: "avoiding mistakes",
                        5: "avoiding others intruding into your world"
                    }
                },
                {
                    text: "What do you strive for most?",
                    options: { 1: "autonomy", 5: "security" }
                }
            ],

            6: [
                {
                    text: "Rather fall into:",
                    options: {
                        1: "rigidity<br/>(as in: being too sure of your own opinion)",
                        6: "doubt<br/>&nbsp;"
                    }
                },
                {
                    text: "What do you strive for most?",
                    options: { 1: "autonomy", 6: "security" }
                },
                {
                    text: "What suits you best?",
                    options: {
                        1: "holding to your own opinion",
                        6: "taking too much account of others opinions"
                    }
                }
            ],

            7: [
                {
                    text: "What suits you best?",
                    options: { 1: "responsibility", 7: "freedom" }
                },
                {
                    text: "What suits you best?",
                    options: {
                        1: "everything must",
                        7: "everything is allowed and nothing must"
                    }
                },
                {
                    text: "What do you strive for most?",
                    options: {
                        1: "following ethical rules",
                        7: "experiencing freedom"
                    }
                }
            ]
        },

        2: {
            5: [
                {
                    text:
                        "Do you need more being alone or especially having fun with other people?",
                    options: { 5: "being alone", 2: "being together" }
                },
                {
                    text: "If you have a lot of contact with others:",
                    options: {
                        2: "that gives you energy",
                        5: "it actually absorbs your energy"
                    }
                },
                {
                    text: "If someone approaches you emotionally, do you:",
                    options: { 5: "rather distance himself", 2: "more support" }
                }
            ],

            6: [
                {
                    text: "What strikes you more?",
                    options: {
                        2: "one's needs",
                        6: "the inconsistencies that appear in one's story"
                    }
                },
                {
                    text: "What happens most?",
                    options: {
                        6: "that you doubt and worry",
                        2: "that you help or advise others too much without being asked"
                    }
                },
                {
                    text: "Where is your focus when you are part of a group?",
                    options: { 2: "to help others", 6: "to fulfill your duty" }
                }
            ],

            7: [
                {
                    text: "What's worst for you?",
                    options: {
                        2: "selfishness",
                        7: "being stuck, having no choices"
                    }
                },
                {
                    text: "What is the hardest for you?",
                    options: { 2: "detachment", 7: "pessimism" }
                },
                {
                    text: "What attracts you most?",
                    options: { 7: "nice plans", 2: "can help" }
                }
            ]
        },

        3: {
            5: [
                {
                    text: "Where is your most loved place?",
                    options: { 3: "on stage", 5: "back stage" }
                },
                {
                    text: "What scares you the most?",
                    options: {
                        3: "that you would fail",
                        5: "that you would not know/understand something"
                    }
                },
                {
                    text: "What is the most important?",
                    options: {
                        3: "being recognized for your successes",
                        5: "being recognized for your expertise"
                    }
                }
            ],

            6: [
                {
                    text: "What do you think is the worst?",
                    options: { 3: "slowness", 6: "negligence" }
                },
                {
                    text: "Do you want most:",
                    options: {
                        3: "recognition for your achievements",
                        6: "certainty that everything is well prepared"
                    }
                },
                {
                    text: "What suits you most?",
                    options: { 6: "doubt", 3: "result as soon as possible" }
                }
            ],

            7: [
                {
                    text: "What scares you the most:",
                    options: {
                        3: "that you would fail",
                        7: "that you would miss a nice experience"
                    }
                },
                {
                    text: "What triggers you more?",
                    options: { 7: "adventure", 3: "competition" }
                },
                {
                    text: "What do you like most?",
                    options: {
                        3: "reach my goal as soon as possible",
                        7: "brainstorm about possibilities and options"
                    }
                }
            ]
        },

        4: {
            5: [
                {
                    text: "What do you prefer more?",
                    options: { 4: "depth of emotions", 5: "depth of knowledge" }
                },
                {
                    text: "What's the worst?",
                    options: { 5: "overt emotionality", 4: "superficiality" }
                },
                {
                    text: "What suits you best?",
                    options: {
                        4: "feeling deep and strong emotions and talking about them with others",
                        5: "avoiding such emotional conversations"
                    }
                }
            ],

            6: [
                {
                    text: "Are you more likely to lose yourself in:",
                    options: {
                        4: "homesickness and longing",
                        6: "doom scenarios in my head"
                    }
                },
                {
                    text: "What suits you best?",
                    options: { 4: "meaning of purpose", 6: "safety" }
                },
                {
                    text: "What do you want most?",
                    options: { 4: "finding your own meaning", 6: "certainty" }
                }
            ],

            7: [
                {
                    text: "If you had to choose, would you rather be:",
                    options: { 4: "unhappy", 7: "numb" }
                },
                {
                    text: "What are you most looking for:",
                    options: {
                        4: "the meaning of your life",
                        7: "new experiences"
                    }
                },
                {
                    text: "Is your attention mainly to:",
                    options: { 4: "the past", 7: "the future" }
                }
            ]
        }
    }
};

export default texts;
