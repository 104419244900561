const texts = {
    title: "PERSONALITY TEST",
    description: `The intention is that you indicate to what extent the following statements are applicable to you.<br />
    Complete the test based on how you NOW think and are in a professional context.<br /><br />
    Thank you and good luck!`,
    privacy_first: "I have read and agree to the ",
    privacy_link: "Privacy Policy",
    privacy_last: "",
    start: "Start the test"
};

export default texts;
