import { useState } from "react";
import { Container, Button } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import {
    LanguageType,
    ProfileType,
    SaveAnswerDataType,
    CompileResultType
} from "../types";
import { Round4Texts } from "../languages/GetTexts";
import { Round4TextsType } from "../languages/types";

type Round4Props = {
    currentQuestion: number;
    saveAnswer: (
        data: SaveAnswerDataType,
        result: CompileResultType[],
        finishRound: boolean
    ) => void;
    language: LanguageType;
};

type OrderType = {
    type: ProfileType;
    order: number;
};

type QuestionType = {
    [type: number]: string;
};

const Round4 = ({ currentQuestion, saveAnswer, language }: Round4Props) => {
    const [first, setFirst] = useState(true);
    const [order, setOrder] = useState<OrderType[]>([]);
    const [options, setOptions] = useState<QuestionType | null>(null);
    //const [answers, setAnswer] = useState([]);

    const texts: Round4TextsType = Round4Texts(language);
    const questions: QuestionType[] = texts.questions;

     const currentQuestions = questions[currentQuestion - 1];
     setOptions(currentQuestions);
    /*
    useEffect(() => {
        const currentQuestions = questions[currentQuestion - 1];
        setOptions(currentQuestions);
    }, [currentQuestion]);
    */

    const hasAnswer = () => {
        return order && order.length === 3;
    };

    const position = (type: ProfileType) => {
        const data = isActive(type);
        return data ? data.order + "." : "";
    };

    const isActive = (type: ProfileType) => {
        return order && order.find(x => x.type === type);
    };

    const toggleActive = (type: ProfileType) => {
        if (order && order.find(x => x.type === type)) {
            const newOrder = order
                .filter(x => x.type !== type)
                .map((x, i) => {
                    x.order = i + 1;
                    return x;
                });
            setOrder(newOrder);
        } else {
            setOrder([...order, { type: type, order: order.length + 1 }]);
        }
    };

    const saveOrder = () => {
        const data = { answer: 0, extra: order };
        const result = order
            .filter(x => x.order !== 3)
            .map(item => {
                const value = item.order === 1 ? 5 : 3;
                return { type: item.type, value: value, attr: "value" };
            });
        saveAnswer(data, result, currentQuestion === 3);
        setOrder([]);
    };

    return (
        <>
            {first ? (
                <div className="m-content">
                    <h2>{ReactHtmlParser(texts.explanation)}</h2>
                </div>
            ) : (
                <div className="m-content">
                    <h2>{texts.explanation_question}:</h2>
                    <Container>
                        {options &&
                            Object.keys(options).map(i => {
                                const type = Number(i) as ProfileType;
                                return (
                                    <h4
                                        className={
                                            "round-3__option" +
                                            (isActive(type)
                                                ? " text-secondary"
                                                : "")
                                        }
                                        key={type}
                                        onClick={() => toggleActive(type)}
                                    >
                                        <div className="text-center">
                                            {position(type)}
                                        </div>
                                        {ReactHtmlParser(options[type])}
                                    </h4>
                                );
                            })
                        }
                    </Container>
                </div>
            )}
            <div className="m-actions">
                {first ? (
                    <Button onClick={() => setFirst(false)} size="lg">
                        {texts.button_start}
                    </Button>
                ) : (
                    <Button onClick={() => setOrder([])} size="lg">
                        {texts.button_again}
                    </Button>
                )}
                {hasAnswer() && (
                    <Button size="lg" onClick={() => saveOrder()}>
                        {texts.button_next}
                    </Button>
                )}
            </div>
        </>
    );
};

export default Round4;
