const texts = {
    explanation: `On vous montre toujours un ensemble de 3 images et mots.<br />
    Cliquez sur ces images dans l'ordre de la manière qu'elles vous conviennent le mieux.`,
    button_start: 'Continuer avec les images',
    button_next: 'Suivante',
    button_again: 'De nouveau',
    text_1: `m'attire <span className="important">le plus</span>`,
    text_3: `m'attire <span className="important">le moins</span>`,
};

export default texts;
