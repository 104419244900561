import React, { useState } from 'react';
import { Container, Button } from 'react-bootstrap';
import { LanguageType, ProfileType, SaveAnswerDataType, CompileResultType } from "../types";
import { Round3Texts } from "../languages/GetTexts";
import { Round3TextsType } from "../languages/types";
import ReactHtmlParser from 'react-html-parser';

type AnswerType = {
    type: ProfileType;
    order: number;
}

type Round3Props = {
    currentQuestion: number;
    saveAnswer: (data: SaveAnswerDataType, result: CompileResultType[], finishRound: boolean) => void;
    language: LanguageType;
}

type CenterType = ProfileType[];

const Round3 = ({ currentQuestion, saveAnswer, language }: Round3Props) => {

  const [answers, setAnswer] = useState<AnswerType[]>([]);
  const [order, setOrder] = useState<AnswerType[]>([]);
  const [first, setFirst] = useState(true);
  const centers: CenterType[] = [[8,9,1], [2,3,4], [5,6,7]];
  const options = (currentQuestion <= 3) ? centers[currentQuestion-1] : answers.map(i => i.type);
  const texts: Round3TextsType = Round3Texts(language);

  const renderImages = () => {
    return options.map(type => (
      <div className="a-question -round_3" key={ currentQuestion +'-'+ type} onClick={()=>toggleActive(type)}>
        <div className="a-question__background -round">
          <img alt="type" src={`/img/words/${language}/type-${type}.svg`} className="img-fluid"/>
        </div>
        { isActive(type) && (
          <div className="a-question__cover -round">
            <span>{isActive(type)}</span>
          </div>
        )}
      </div>
    ));
  }

  const isActive = (type: ProfileType) => {
    const item = order.find(x => x.type === type);
    return (item) ? item.order : false;
  }

  const hasAnswer = () => {
    return order && order.length === 3;
  }

  const toggleActive = (type: ProfileType) => {
    if(order && order.find(x => x.type === type)) {
       const newOrder = order.filter(x => x.type !== type).map((x,i) => { x.order = i+1; return x });
       setOrder(newOrder);
    } else {
      setOrder([...order, {type: type, order: order.length + 1}]);
    }
  }

  const saveOrder = () => {

    const data = { answer: 0, extra: order};

    const result = order.filter(x => x.order!==3).map(item => {

      const value = (item.order === 1) ? 2 : 1;

      if(value === 2) {
        setAnswer([...answers, item]);
      }

      return {type: item.type, attr: 'value', value: value };

    });

    saveAnswer(data, result, currentQuestion === 4);
    setOrder([]);
  }

  return (
    <>
      { first ? (
        <div className="m-content">
          <h2>{ReactHtmlParser(texts.explanation)}</h2>
        </div>
      ) : (
        <div className="m-content">
          <h2>1: {ReactHtmlParser(texts.text_1)} -  3: {ReactHtmlParser(texts.text_3)}</h2>
          <Container>
            <div className="a-question__wrapper -round_3">
              {renderImages()}
            </div>
          </Container>
        </div>
      )}
      <div className="m-actions">
        { first ? (
          <Button onClick={()=>setFirst(false)} size="lg">
            {texts.button_start}
          </Button>
        ) : (
          <Button onClick={()=>setOrder([])} size="lg">
              {texts.button_again}
          </Button>
        )}
        { hasAnswer() && (
          <Button onClick={()=>saveOrder()} size="lg">
              {texts.button_next}
          </Button>
        )}
      </div>
    </>
  );
}

export default Round3;

