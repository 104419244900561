import React, { useState } from "react";
import { Container, Button } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import { Round2Texts } from "../languages/GetTexts";
import { Round2TextsType } from "../languages/types";
import { LanguageType, ProfileType, SaveAnswerDataType, CompileResultType } from "../types";

type QuestionType = {
    type: ProfileType;
    question: number;
    factor: number;
}

type TextType = {
    question: number;
    text: string;
}

type Round2Props = {
    currentQuestion: number;
    saveAnswer: (data: SaveAnswerDataType, result: CompileResultType, finishRound: boolean) => void;
    language: LanguageType;
}

const Round2 = ({ currentQuestion, saveAnswer, language }: Round2Props) => {

    const start: QuestionType[] = [
        { type: 1, question: 1, factor: 1 },
        { type: 1, question: 2, factor: 1 },
        { type: 1, question: 3, factor: 1.2 },
        { type: 1, question: 4, factor: 1 },
        { type: 1, question: 5, factor: 1 },
        { type: 1, question: 6, factor: 1 },

        { type: 2, question: 7, factor: 1 },
        { type: 2, question: 8, factor: 1.2 },
        { type: 2, question: 9, factor: 1 },
        { type: 2, question: 10, factor: 1 },
        { type: 2, question: 11, factor: 1 },
        { type: 2, question: 12, factor: 1 },

        { type: 3, question: 13, factor: 1 },
        { type: 3, question: 14, factor: 1.2 },
        { type: 3, question: 15, factor: 1 },
        { type: 3, question: 16, factor: 1 },
        { type: 3, question: 17, factor: 1 },
        { type: 3, question: 18, factor: 1 },

        { type: 4, question: 19, factor: 1.2 },
        { type: 4, question: 20, factor: 1 },
        { type: 4, question: 21, factor: 1 },
        { type: 4, question: 22, factor: 1 },
        { type: 4, question: 23, factor: 1 },
        { type: 4, question: 24, factor: 1 },

        { type: 5, question: 25, factor: 1 },
        { type: 5, question: 26, factor: 1 },
        { type: 5, question: 27, factor: 1.2 },
        { type: 5, question: 28, factor: 1 },
        { type: 5, question: 29, factor: 1 },
        { type: 5, question: 30, factor: 1 },

        { type: 6, question: 31, factor: 1 },
        { type: 6, question: 32, factor: 1.2 },
        { type: 6, question: 33, factor: 1 },
        { type: 6, question: 34, factor: 1 },
        { type: 6, question: 35, factor: 1 },
        { type: 6, question: 36, factor: 1 },

        { type: 7, question: 37, factor: 1 },
        { type: 7, question: 38, factor: 1 },
        { type: 7, question: 39, factor: 1.2 },
        { type: 7, question: 40, factor: 1 },
        { type: 7, question: 41, factor: 1 },
        { type: 7, question: 42, factor: 1 },

        { type: 8, question: 43, factor: 1 },
        { type: 8, question: 44, factor: 1 },
        { type: 8, question: 45, factor: 1.2 },
        { type: 8, question: 46, factor: 1 },
        { type: 8, question: 47, factor: 1 },
        { type: 8, question: 48, factor: 1 },

        { type: 9, question: 49, factor: 1.2 },
        { type: 9, question: 50, factor: 1 },
        { type: 9, question: 51, factor: 1 },
        { type: 9, question: 52, factor: 1 },
        { type: 9, question: 53, factor: 1 },
        { type: 9, question: 54, factor: 1 }
    ];

    const [first, setFirst] = useState(true);
    const [active, setActive] = useState(0);
    const [questions, setQuestions] = useState(start);
    const texts: Round2TextsType = Round2Texts(language);

    setQuestions(start);

    /*
    const randomizeQuestions = () => {
      const random = start.sort(() => 0.5 - Math.random());
      setQuestions(random);
    };

    useEffect(() => {
      randomizeQuestions();
    }, []);
    */
    

    const questionText = () => {
        const question =  texts.questions.find(
            (i:TextType) =>
                i.question ===
                questions[currentQuestion - 1]
                    .question
        )
        if(question === undefined) {
            return 'Er is een fout opgetreden'
        }
        return question.text;
    }

    const saveQuestion = () => {
        setActive(0);
        const question: QuestionType = questions[currentQuestion - 1];
        const completed: boolean = currentQuestion === questions.length;
        const value: number =
            question.factor === 1 || active === 3
                ? active
                : active > 3
                ? active * question.factor
                : Math.round(active * 10)/10;
        saveAnswer(
            { extra: question, answer: value },
            { type: question.type, attr: "value", value: value },
            completed
        );
    }

    const renderButtons = () => {
        const options = [1, 2, 3, 4, 5];

        return options.map(i => (
            <Button
                variant={active === i ? "secondary" : "primary"}
                className="mr-1"
                size="lg"
                onClick={() => setActive(i)}
            >
                {i}
            </Button>
        ));
    }

    return (
        <>
            {first ? (
                <div className="m-content">
                    <h2>
                        {ReactHtmlParser(texts.explanation)}
                    </h2>
                </div>
            ) : (
                <div className="m-content">
                    <Container>
                        <div className="a-quote">
                            <h2 className="-quote">
                                {ReactHtmlParser(questionText())}
                            </h2>
                        </div>
                        <div className="mt-4 mb-1">
                            {texts.scale_left}
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {texts.scale_right}&nbsp;&nbsp;&nbsp;
                        </div>

                        <div>{renderButtons()}</div>
                    </Container>
                </div>
            )}
            <div className="m-actions">
                {first && (
                    <Button onClick={() => setFirst(false)} size="lg">
                        {texts.button_start}
                    </Button>
                )}
                {active !== 0 && (
                    <Button size="lg" onClick={() => saveQuestion()}>
                        {texts.button_next}
                    </Button>
                )}
            </div>
        </>
    );
};

export default Round2;
