const texts = {
    explanation: `You will see a set of 3 statements.<br/>
    Click on these statements in the order in which they appeal to you the most.`,
    explanation_question: `Click on these statements in the order in which they appeal to you the most.`,
    button_start: "Go to statements",
    button_next: "Next statement",
    button_again: "Reset",
    questions: [
        {
            8: "If I am annoyed or frustrated, those around me will quickly notice; everyone can know what I don't like! I don't hide my anger and usually express it immediately. Once it's out, I can quickly forget about it and the work for me just continues.",
            9: "I very rarely get really angry. When I'm angry, I go into passive resistance rather than expressing myself directly. Actually, I hope that the situation will blow over by itself so that the harmony and the atmosphere are not disturbed. After all, it gives me peace of mind when everyone around me is happy.",
            1: "If I am annoyed or frustrated, my surroundings will notice this mainly from my facial expressions or because I sigh more, for example. I won't be quick to express annoyances and rather bottle it up… and then things could explode. At work, I do everything I can to keep my emotions under control and my restrained frustrations will not show up easily. This happens faster at home."
        },
        {
            2: "I am a pleaser; I like to be there for others. I want to be everyone's friend. After all, I sense what others need and I like to help. I will always help others first before doing anything for myself. I like it when others need me.",
            3: "I am a winner. Failure is not an option for me and so I strive to be successful. Actually, I'm a workaholic. Sitting still is not for me.",
            4: "I have mood swings: one moment I can be very happy and the next I feel misunderstood. Life can be tough. Superficial conversations are not for me: there must be depth in my relationships with others. The worst would be if others see me as a gray mouse; I really want to make a difference."
        },
        {
            5: "I can focus on the same subject for a long time if it is in my field of interest. Gaining knowledge about topics that interest me is something I am constantly working on – after all, you can never know enough. I literally take a step back when approached by a very pushy person. I like to be alone and strive to be independent.",
            6: "I like to be prepared and I like to learn. I will first consider all options before making a decision. When in doubt, I will consult people in my environment whose opinion I trust. I am sometimes called a doomsayer because I consider all possible scenarios. I want clarity and know where I stand – trust is important; after all, “we are strong together”!",
            7: "Dull or routine is not for me. Just give me nice experiences, being able to express new ideas and have nice prospects! I like brainstorming and discussing options. It's hard for me to choose because I'd rather not miss anything. Not a defined job for me with many restrictions and rules. Life should be fun!"
        }
    ]
};

export default texts;
