import { Container } from 'react-bootstrap';

const Header = () => {

    return (
    
        <Container>
            <img src="/logo_cowize-white.svg" alt="cowize logo" className="m-header__logo" />
        </Container>
       
    );
}


export default Header;