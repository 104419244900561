import React, { useState } from "react";
import { Container, Button } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import { Round5Texts } from "../languages/GetTexts";
import { Round5TextsType } from "../languages/types";

import {
    LanguageType,
    ProfileType,
    SaveAnswerDataType,
    CompileResultType,
    ResultProfileType
} from "../types";

type QuestionType = {
    text: string;
    options: {
        [profile: number]: string;
    };
    value: number;
};

type Questions = {
    [profile_1: number]: {
        [profile_2: number]: QuestionType[];
    };
};

type Round5Props = {
    currentQuestion: number;
    saveAnswer: (
        data: SaveAnswerDataType,
        result: CompileResultType,
        finishRound: boolean
    ) => void;
    language: LanguageType;
    types: {
        [index: number]: ResultProfileType[];
    };
};

const Round5 = ({
    currentQuestion,
    saveAnswer,
    types,
    language
}: Round5Props) => {
    const [active, setActive] = useState<null | ProfileType>(null);
    const [question, setQuestion] = useState<QuestionType | null>(null);
    const texts: Round5TextsType = Round5Texts(language);

    const questions: Questions = texts.questions;


    const round = Math.ceil(currentQuestion / 3);
    const q = (currentQuestion - 1) % 3;
    let sortedTypes = [types[round][0].type, types[round][1].type].sort(
      (a, b) => a - b
    );
    let sortedQuestion = questions[sortedTypes[0]][sortedTypes[1]][q];
    if (types[round][0].value > types[round][1].value + 2) {
      saveAnswer(
        { extra: sortedQuestion, answer: types[round][0].type },
        {
          type: types[round][0].type as ProfileType,
          attr: "value",
          value: 2,
        },
        currentQuestion === 9
      );
      setQuestion(null);
    } else {
      setQuestion(sortedQuestion);
    }
    /*
    useEffect(() => {
        const round = Math.ceil(currentQuestion / 3);
        const q = (currentQuestion - 1) % 3;
        let sortedTypes = [types[round][0].type, types[round][1].type].sort(
            (a, b) => a - b
        );
        let sortedQuestion = questions[sortedTypes[0]][sortedTypes[1]][q];
        if (types[round][0].value > types[round][1].value + 2) {
            saveAnswer(
                { extra: sortedQuestion, answer: types[round][0].type },
                {
                    type: types[round][0].type as ProfileType,
                    attr: "value",
                    value: 2
                },
                currentQuestion === 9
            );
            setQuestion(null);
        } else {
            setQuestion(sortedQuestion);
        }
    }, [currentQuestion]);
    */

    const saveQuestion = () => {
        if (question && active) {
            saveAnswer(
                { extra: question, answer: active },
                { type: active, attr: "value", value: question.value },
                currentQuestion === 9
            );
            setActive(null);
        }
    };

    if (question) {
        return (
            <>
                <div className="m-content">
                    <Container>
                        <div className="a-quote">
                            <h2 className="-quote">
                                {ReactHtmlParser(question.text)}
                            </h2>
                        </div>

                        {Object.keys(question.options).map(type => (
                            <Button
                                variant={
                                    active === Number(type)
                                        ? "secondary"
                                        : "primary"
                                }
                                className="mr-3 ml-3"
                                size="lg"
                                onClick={() =>
                                    setActive(Number(type) as ProfileType)
                                }
                            >
                                {ReactHtmlParser(
                                    question.options[Number(type)]
                                )}
                            </Button>
                        ))}
                    </Container>
                </div>

                <div className="m-actions">
                    {active && (
                        <Button size="lg" onClick={() => saveQuestion()}>
                            {texts.button_next}
                        </Button>
                    )}
                </div>
            </>
        );
    } else {
        return null;
    }
};

export default Round5;
