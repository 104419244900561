import { useState } from "react";
import { Container, Button } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import {
    LanguageType,
    ProfileType,
    SaveAnswerDataType,
    CompileResultType,
    ResultProfileType
} from "../types";
import { Round6Texts } from "../languages/GetTexts";
import { Round6TextsType } from "../languages/types";

type Round6Props = {
    currentQuestion: number;
    saveAnswer: (
        data: SaveAnswerDataType,
        result: CompileResultType,
        finishRound: boolean
    ) => void;
    language: LanguageType;
    types: {
        [profile: number]: ResultProfileType;
    };
};

type QuestionType = {
    text: string;
    options: {
        [profile: number]: string;
    };
};

type QuestionsType = {
    [profile_1: number]: {
        [profile_2: number]: QuestionType[];
    };
};

const Questions = ({
    currentQuestion,
    saveAnswer,
    types,
    language
}: Round6Props) => {
    const [active, setActive] = useState<null | ProfileType>(null);
    const [question, setQuestion] = useState<null | QuestionType>(null);
    const texts: Round6TextsType = Round6Texts(language);

    const questions: QuestionsType = texts.questions;

    const round = Math.ceil(currentQuestion / 3);
    const q = (currentQuestion - 1) % 3;
    switch (round) {
      case 1:
        setQuestion(questions[types[1].type][types[2].type][q]);
        break;
      case 2:
        setQuestion(questions[types[1].type][types[3].type][q]);
        break;
      case 3:
        setQuestion(questions[types[2].type][types[3].type][q]);
        break;
    }

    /*
    useEffect(() => {
        const round = Math.ceil(currentQuestion / 3);
        const q = (currentQuestion - 1) % 3;
        switch (round) {
            case 1:
                setQuestion(questions[types[1].type][types[2].type][q]);
                break;
            case 2:
                setQuestion(questions[types[1].type][types[3].type][q]);
                break;
            case 3:
                setQuestion(questions[types[2].type][types[3].type][q]);
                break;
        }
    }, [currentQuestion]);
    */

    const saveQuestion = () => {
        if (question && active) {
            saveAnswer(
                { extra: question, answer: active },
                { type: active, attr: "order", value: 1 },
                currentQuestion === 9
            );
            setActive(null);
        }
    };

    if (question !== null) {
        return (
            <>
                <div className="m-content">
                    <Container>
                        <div className="a-quote">
                            <h2 className="-quote">
                                {question && ReactHtmlParser(question.text)}
                            </h2>
                        </div>

                        {question &&
                            Object.keys(question.options).map(type => {
                                return (
                                    <Button
                                        variant={
                                            active === Number(type)
                                                ? "secondary"
                                                : "primary"
                                        }
                                        className="mr-3 ml-3"
                                        size="lg"
                                        onClick={() =>
                                            setActive(Number(
                                                type
                                            ) as ProfileType)
                                        }
                                    >
                                        {ReactHtmlParser(
                                            question.options[Number(type)]
                                        )}
                                    </Button>
                                );
                            })}
                    </Container>
                </div>

                <div className="m-actions">
                    {active && (
                        <Button size="lg" onClick={() => saveQuestion()}>
                            {texts.button_next}
                        </Button>
                    )}
                </div>
            </>
        );
    } else {
        return null;
    }
};

export default Questions;
