import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { LanguageType, ResultType } from "../types";
import { WelcomeTexts } from "../languages/GetTexts";
import { WelcomeTextsType } from "../languages/types";
import ReactHtmlParser from 'react-html-parser';
import { useMutation } from "@apollo/client";
import { SCAN_START } from "../graphql/mutations/scan";

type WelcomeProps = {
    id: string;
    language: LanguageType;
    setRound: (round: number) => void;
    setCompetences: (active: string[]) => void;
    setResult: (result: ResultType) => void
    setProgressCount: (quantity: number) => void;
    setCurrentQuestion: (number: number) => void;
};

const Welcome = ({ id, language, setRound, setCompetences, setResult, setProgressCount, setCurrentQuestion }: WelcomeProps) => {

    const [accept, setAccept] = useState(false);
    const texts: WelcomeTextsType = WelcomeTexts(language);

    const [startScan] = useMutation(SCAN_START, {
        variables: { scanUuid: id }
    });

    useEffect(() => {

        const pathArray = window.location.pathname.split("/");
        localStorage.setItem('tenantId', pathArray[1]);
        localStorage.setItem("testId", pathArray[2]);

      fetch("https://api.cowisoft.be/sanctum/csrf-cookie")
        .then((response) => response.json())
        .then((data) => console.log(data));

    }, []);

    
    const startTest = () => {

        startScan();
        /*
        axios
            .post("/api/start-test", { test_uuid: id })
            .then(response => {

                const {competences_start, competences, result, last } = response.data;

                setCompetences(competences ? competences : []);
                setResult(result);
                setRound(competences_start ? 7 : last.round);
                setCurrentQuestion(competences_start ? 1 : last.question);

                if (competences_start) {
                    setProgressCount(competences.length * 5)
                } else if (competences.length) {
                    setProgressCount(83 + competences.length * 5)
                }
            })
            .catch(error => {});
            */
    };

    return (
        <>
            <div className="m-content">
                <h1>
                    COWIZE
                    <br />
                    <small>{texts.title}</small>
                </h1>
                <p className="lead">
                    {ReactHtmlParser(texts.description)}
                </p>
            </div>
            <div className="m-actions">
                <div className="custom-control custom-checkbox">
                    <input
                        type="checkbox"
                        className="custom-control-input a-policy"
                        id="policyCheckbox"
                        onClick={() => setAccept(!accept)}
                    />
                    <label
                        className="custom-control-label a-policy__label"
                        htmlFor="policyCheckbox"
                    >
                        {texts.privacy_first}{" "}
                        <a
                            href="https://cowize.be/privacyverklaring/"
                            target="_blank" rel="noreferrer"
                        >
                            {texts.privacy_link}
                        </a>{" "}
                        {texts.privacy_last}.
                    </label>
                </div>

                <br />
                <Button
                    disabled={!accept}
                    onClick={() => startTest()}
                    size="lg"
                >
                    {texts.start}
                </Button>
            </div>
        </>
    );
};

export default Welcome;
