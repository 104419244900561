const texts = {
    button_next: "Volgende",

    questions: {
        8: {
            2: [
                {
                    text:
                        'Ga je <span class="important">eerder</span> hints geven als je iets wil of ga je gewoon rechttoe, rechtaan zeggen wat je wil?',
                    options: {
                        2: "eerder hints geven",
                        8: "rechttoe, rechtaan zeggen"
                    }
                },
                {
                    text:
                        'Wat is voor jou <span class="important">het belangrijkste</span>?',
                    options: { 2: "erkenning krijgen", 8: "autonomie hebben" }
                },
                {
                    text:
                        'Wat vind je <span class="important">het ergst</span>?',
                    options: { 8: "gevlei", 2: "botheid" }
                }
            ],
            3: [
                {
                    text:
                        "Zou je de zwakkeren beschermen ten koste van je eigen succes of kies je in dat geval toch voor je eigen succes?",
                    options: { 8: "zwakkeren beschermen", 3: "eigen succes" }
                },
                {
                    text: 'Wat is <span class="important">erger</span>?',
                    options: { 3: "traagheid", 8: "onrechtvaardigheid" }
                },
                {
                    text:
                        'Waar streef jij <span class="important">meer</span> naar?',
                    options: {
                        8: "autonomie verwerven",
                        3: "erkenning krijgen"
                    }
                }
            ],
            4: [
                {
                    text:
                        'Is (over-)emotionaliteit voor jou <span class="important">eerder</span> een kracht of een zwakte?',
                    options: { 4: "een kracht", 8: "een zwakte" }
                },
                {
                    text: 'Wat is <span class="important">het ergst</span>?',
                    options: { 4: "ongevoelig", 8: "onrechtvaardigheid" }
                },
                {
                    text: 'Wat is <span class="important">het ergst</span>?',
                    options: { 4: "emotieloos zijn", 8: "machteloos zijn" }
                }
            ],
            5: [
                {
                    text: 'Wat is <span class="important">het ergst</span>?',
                    options: { 8: "machteloosheid", 5: "onwetendheid" }
                },
                {
                    text:
                        'Zouden de anderen <span class="important">eerder</span> zeggen dat je hen intimideert of dat je afstandelijk bent?',
                    options: { 8: "intimideert", 5: "afstandelijk" }
                },
                {
                    text:
                        'Waardeert men jou <span class="important">meest</span> voor je analytisch vermogen of voor je daadkracht?',
                    options: { 5: "analytisch vermogen", 8: "daadkracht" }
                }
            ],
            6: [
                {
                    text:
                        "Als je het niet eens bent met de mening van de groep:",
                    options: {
                        6: 'ga je dan <span class="important">eerst</span> inschatten hoeveel medestanders je hebt',
                        8: 'ga je dan <span class="important">meteen</span> impulsief je mening geven'
                    }
                },
                {
                    text:
                        'Waar streef jij <span class="important">meest</span> naar?',
                    options: {
                        6: "zekerheid/veiligheid verwerven",
                        8: "autonomie verwerven"
                    }
                },
                {
                    text:
                        'Wat doe je <span class="important">meestal</span> meer?',
                    options: {
                        8: "de groep trekken",
                        6: "ondersteuning bieden"
                    }
                }
            ],
            7: [
                {
                    text:
                        'Waar herken je je <span class="important">meest</span> in?',
                    options: {
                        7: "een fladderende vlinder",
                        8: "een sterke olifant"
                    }
                },
                {
                    text:
                        'Wat is <span class="important">belangrijker</span> voor jou?',
                    options: { 7: "gelukkig zijn", 8: "sterk en krachtig zijn" }
                },
                {
                    text:
                        'Waar heb je de <span class="important">grootste</span> hekel aan?',
                    options: { 8: "oneerlijkheid", 7: "saaiheid" }
                }
            ]
        },
        9: {
            2: [
                {
                    text: 'Wat is <span class="important">het ergste</span>?',
                    options: { 9: "conflict", 2: "afstandelijkheid" }
                },
                {
                    text: 'Wat is <span class="important">het ergste</span>?',
                    options: {
                        2: "niet kunnen helpen",
                        9: "niet gezien/opgemerkt worden"
                    }
                },
                {
                    text:
                        "Wacht jij tot mensen bij jou komen en hulp vragen of zoek jij al naar manieren om te helpen zelfs zonder dat anderen het vragen?",
                    options: {
                        9: "bij jou komen en hulp vragen",
                        2: "zoek zelf al naar manieren om te helpen"
                    }
                }
            ],
            3: [
                {
                    text:
                        'Wat is <span class="important">het ergst</span> voor jou?',
                    options: { 9: "jachtigheid", 3: "traagheid" }
                },
                {
                    text:
                        'Wat past <span class="important">meest</span> bij jou?',
                    options: { 9: "te geduldig", 3: "te ongeduldig" }
                },
                {
                    text:
                        'Ga jij <span class="important">eerder</span> spontaan en zonder probleem op de voorgrond treden of hou je je liever aan de zijlijn onder de radar?',
                    options: {
                        3: "op de voorgrond treden",
                        9: "aan de zijlijn"
                    }
                }
            ],
            4: [
                {
                    text:
                        'Zoek je <span class="important">vooral</span> innerlijke rust of intensiteit in je gevoelsleven?',
                    options: {
                        9: "innerlijke rust",
                        4: "intensiteit in mijn gevoelsleven"
                    }
                },
                {
                    text:
                        'Waar streef je <span class="important">meest</span> naar?',
                    options: {
                        4: "erkenning krijgen voor wie je bent",
                        9: "conflicten vermijden"
                    }
                },
                {
                    text:
                        'Waarnaar zoek je <span class="important">meest</span>?',
                    options: { 4: "zingeving", 9: "samenhang" }
                }
            ],
            5: [
                {
                    text: "Zijn we allen één of allemaal alleen?",
                    options: { 9: "allen één", 5: "allemaal alleen" }
                },
                {
                    text:
                        'Wat zoek je het <span class="important">meest</span>?',
                    options: { 5: "onafhankelijkheid", 9: "samenhang/harmonie" }
                },
                {
                    text:
                        'Is het voor jou <span class="important">makkelijker</span> om je aandacht te focussen of dwaal je makkelijk af met je gedachten?',
                    options: {
                        5: "aandacht te focussen",
                        9: "dwaal makkelijk af"
                    }
                }
            ],
            6: [
                {
                    text: 'Wat is <span class="important">belangrijker</span>?',
                    options: { 6: "je plicht vervullen", 9: "tevreden zijn" }
                },
                {
                    text: 'Wat doe je <span class="important">meest</span>?',
                    options: {
                        9: "streven naar innerlijke rust",
                        6: "piekeren"
                    }
                },
                {
                    text: "Bij problemen:",
                    options: {
                        6: "breng je eerst alles in kaart",
                        9: 'hoop je <span class="important">eerder</span> dat ze vanzelf wel zullen over gaan'
                    }
                }
            ],
            7: [
                {
                    text:
                        'Wat past het <span class="important">best</span> bij jou?',
                    options: { 9: "geduld", 7: "ongeduld" }
                },
                {
                    text: 'Wil jij <span class="important">vooral</span>:',
                    options: { 9: "rust en harmonie", 7: "vreugde en plezier" }
                },
                {
                    text:
                        'Wat spreekt jou <span class="important">meest</span> aan?',
                    options: {
                        9: "productieve routine",
                        7: "verandering/iets nieuws"
                    }
                }
            ]
        },
        1: {
            2: [
                {
                    text:
                        'Wat vind je het <span class="important">lastigst</span>?',
                    options: {
                        1: "dat men je niet nauwkeurig genoeg vindt",
                        2: "dat men je niet behulpzaam genoeg vindt"
                    }
                },
                {
                    text:
                        'Wat vind je het <span class="important">ergst</span>?',
                    options: { 1: "wispelturigheid", 2: "botheid" }
                },
                {
                    text:
                        'Wat is voor jou het <span class="important">belangrijkst</span>?',
                    options: {
                        1: "autonomie verwerven",
                        2: "erkenning krijgen"
                    }
                }
            ],
            3: [
                {
                    text:
                        'Wat <span class="important">stoort</span> jou het <span class="important">meeste</span>?',
                    options: { 1: "nonchalance", 3: "traagheid" }
                },
                {
                    text:
                        'Wat herken jij je <span class="important">meest</span> in?',
                    options: {
                        1: "te ontoegeeflijk vasthouden aan je eigen mening",
                        3: "je te veel aanpassen aan wat belangrijke mensen van jou verwachten"
                    }
                },
                {
                    text:
                        'Waar ga je <span class="important">eerst</span> voor?',
                    options: { 1: "groepssucces", 3: "eigen succes" }
                }
            ],
            4: [
                {
                    text: "Hoe ga je om met je eigen emoties?",
                    options: {
                        4: "beleef je deze graag ten volle",
                        1: "probeer je deze eerder te beheersen"
                    }
                },
                {
                    text:
                        'Wat zou je <span class="important">het liefst</span> hebben, dat anderen jou omschrijven als',
                    options: { 4: "origineel", 1: "rechtlijnig" }
                },
                {
                    text:
                        'Wat vind je <span class="important">het ergste</span>',
                    options: { 1: "nonchalance", 4: "onechtheid" }
                }
            ],
            5: [
                {
                    text:
                        'Wat <span class="important">stoort</span> jou het meest?',
                    options: { 5: "onwetendheid", 1: "gemakzucht" }
                },
                {
                    text:
                        'Wat is <span class="important">het belangrijkste</span> voor jou?',
                    options: {
                        1: "fouten vermijden",
                        5: "vermijden dat anderen in jouw wereld binnendringen"
                    }
                },
                {
                    text:
                        'Waar streef jij <span class="important">meest</span> naar?',
                    options: { 1: "autonomie", 5: "zekerheid" }
                }
            ],
            6: [
                {
                    text: "Verval je eerder in:",
                    options: {
                        1: "rigiditeit<br/>(als in: te zeker zijn van je eigen mening)",
                        6: "twijfel<br/>&nbsp;"
                    }
                },
                {
                    text:
                        'Waar streef jij het <span class="important">meest</span> naar?',
                    options: { 1: "autonomie", 6: "zekerheid" }
                },
                {
                    text:
                        'Wat past <span class="important">best</span> bij jou?',
                    options: {
                        1: "vasthouden aan je eigen mening",
                        6: "teveel rekening houden met anderen hun mening"
                    }
                }
            ],
            7: [
                {
                    text:
                        'Wat past <span class="important">best</span> bij jou?',
                    options: { 1: "verantwoordelijkheid", 7: "vrijheid" }
                },
                {
                    text:
                        'Wat past <span class="important">best</span> bij jou?',
                    options: { 1: "alles moet", 7: "alles mag en niets moet" }
                },
                {
                    text:
                        'Waar streef je <span class="important">meest</span> naar?',
                    options: {
                        1: "het volgen van ethische regels",
                        7: "vrijheid ervaren"
                    }
                }
            ]
        },
        2: {
            5: [
                {
                    text:
                        'Heb je <span class="important">meer</span> nood aan alleen zijn of <span class="important">vooral</span> aan gezellig samen zijn met andere mensen ?',
                    options: { 5: "alleen zijn", 2: "gezellig samen zijn" }
                },
                {
                    text: "Als je veel contact hebt met anderen:",
                    options: {
                        2: "geeft jou dat net energie",
                        5: "slorpt dit eigenlijk energie van je op"
                    }
                },
                {
                    text: "Als iemand je emotioneel benadert, ga je dan:",
                    options: {
                        5: '<span class="important">eerder</span> afstand nemen',
                        2: '<span class="important">eerder</span> ondersteunen'
                    }
                }
            ],
            6: [
                {
                    text:
                        'Wat valt jou <span class="important">eerder</span> op?',
                    options: {
                        2: "iemand’s behoeften",
                        6: "de inconsistenties die in iemand’s verhaal voorkomen"
                    }
                },
                {
                    text: 'Wat gebeurt <span class="important">meest</span>?',
                    options: {
                        6: "dat je twijfelt en piekert",
                        2: "dat je te veel ongevraagd anderen helpt of adviseert"
                    }
                },
                {
                    text:
                        'Waar ligt bij jou de <span class="important">focus</span> als je deel uitmaakt van een groep?',
                    options: {
                        2: "op anderen helpen",
                        6: "op je plicht vervullen"
                    }
                }
            ],
            7: [
                {
                    text:
                        'Wat is het <span class="important">ergst</span> voor jou',
                    options: {
                        2: "egoïsme",
                        7: "vastzitten, geen keuzes hebben"
                    }
                },
                {
                    text: "Wat is voor jou het moeilijkst?",
                    options: { 2: "afstandelijkheid", 7: "pessimisme" }
                },
                {
                    text:
                        'Wat trekt jou het <span class="important">meest</span> aan?',
                    options: { 7: "leuke plannen", 2: "kunnen helpen" }
                }
            ]
        },
        3: {
            5: [
                {
                    text:
                        'Waar is jouw <span class="important">meest</span> geliefde plaats?',
                    options: { 3: "on stage", 5: "back stage" }
                },
                {
                    text:
                        'Wat <span class="important">schrikt</span> jou het <span class="important">meest</span> af?',
                    options: {
                        3: "dat je zou falen ",
                        5: "dat je iets niet zou weten/begrijpen"
                    }
                },
                {
                    text:
                        'Wat is het <span class="important">belangrijkste</span>?',
                    options: {
                        3: "erkend worden voor je successen",
                        5: "erkend worden voor je expertise"
                    }
                }
            ],
            6: [
                {
                    text:
                        'Wat vind je het <span class="important">ergst</span>?',
                    options: { 3: "traagheid", 6: "nalatigheid" }
                },
                {
                    text: 'Wil je <span class="important">meest</span>:',
                    options: {
                        3: "erkenning voor je prestaties",
                        6: "zekerheid dat alles goed voorbereid is"
                    }
                },
                {
                    text:
                        'Wat past <span class="important">meest</span> bij jou?',
                    options: { 6: "twijfelen", 3: "zo snel mogelijk resultaat" }
                }
            ],
            7: [
                {
                    text:
                        'Wat schrikt jou het <span class="important">meest</span> af',
                    options: {
                        3: "dat je zou falen",
                        7: "dat je een leuke ervaring zou missen"
                    }
                },
                {
                    text:
                        'Wat triggert jou <span class="important">meer</span>?',
                    options: { 7: "avontuur", 3: "competitie" }
                },
                {
                    text:
                        'Wat vind je het <span class="important">leukst</span>?',
                    options: {
                        3: "zo snel mogelijk mijn doel bereiken",
                        7: "brainstormen over mogelijkheden en opties"
                    }
                }
            ]
        },
        4: {
            5: [
                {
                    text:
                        'Wat verkies jij <span class="important">meer</span>?',
                    options: {
                        4: "diepgang in emoties",
                        5: "diepgang in kennis"
                    }
                },
                {
                    text: 'Wat is het <span class="important">ergst</span>?',
                    options: {
                        5: "openlijke emotionaliteit",
                        4: "oppervlakkigheid"
                    }
                },
                {
                    text:
                        'Wat <span class="important">past best</span> bij jou?',
                    options: {
                        4: "het voelen van diepe en sterke emoties en erover praten met anderen",
                        5: "dergelijke emotionele gesprekken vermijden"
                    }
                }
            ],
            6: [
                {
                    text:
                        'Kan je jezelf <span class="important">eerder</span> verliezen in:',
                    options: {
                        4: "heimwee en verlangen",
                        6: "doemscenario’s in mijn hoofd"
                    }
                },
                {
                    text:
                        'Wat <span class="important">past best</span> bij jou?',
                    options: { 4: "zingeving", 6: "veiligheid" }
                },
                {
                    text: 'Wat wil je <span class="important">meest</span>?',
                    options: { 4: "je eigen betekenis vinden", 6: "zekerheid" }
                }
            ],
            7: [
                {
                    text:
                        'Als je moest kiezen, ben je dan <span class="important">nog liever</span>:',
                    options: { 4: "ongelukkig", 7: "gevoelloos" }
                },
                {
                    text:
                        'Waar ben jij <span class="important">het meest</span> naar op zoek:',
                    options: {
                        4: "de betekenis van jouw leven",
                        7: "nieuwe ervaringen"
                    }
                },
                {
                    text:
                        'Gaat je <span class="important">aandacht vooral naar</span>:',
                    options: { 4: "het verleden", 7: "de toekomst" }
                }
            ]
        }
    }
};

export default texts;
