const texts = {
    explanation: `Vous verrez un ensemble de 3 déclarations.<br/>
    Cliquez sur ces images dans l'ordre de la manière qu'elles vous conviennent le mieux.`,
    explanation_question: `Cliquez sur ces images dans l'ordre de la manière qu'elles vous conviennent le mieux.`,
    button_start: 'Continuer avec les déclarations',
    button_next: 'Suivante',
    button_again: 'De nouveau',
    questions: [
        {
            8: "Si je suis agacé ou frustré, mon environnement le remarquera rapidement ; tout le monde peut savoir ce que je n'aime pas ! Je ne cache pas ma colère et l'exprime généralement immédiatement. Une fois qu'il est sorti, je peux rapidement l'oublier et pour moi, le travail peut continuer comme avant.",
            9: "Je me mets très rarement en colère. Quand je suis en colère, je fais une résistance passive plutôt que de m'exprimer directement. En fait, j'espère que la situation s'envolera d'elle-même pour que l'harmonie et l'atmosphère ne soient pas perturbées. Après tout, cela me rassure lorsque tout le monde autour de moi est heureux.",
            1: "Si je suis ennuyé ou frustré, mon entourage le remarquera principalement à cause de mes expressions faciales ou parce que je soupire plus, par exemple. Je ne m’exprime pas facilement et je vais plutôt embouteiller les choses qui m’énervent… et alors les choses pourraient exploser. Au travail, je fais tout ce que je peux pour contrôler mes émotions. Mes frustrations contenues ne se manifesteront pas facilement. Cela se produit plus rapidement à la maison."
        },
        {
            2: "J’aime beaucoup aider les autres.; J'aime être là pour les autres. Je veux être l'ami de tout le monde. Après tout, je sens ce dont les autres ont besoin et j'aime être là pour eux. J'aiderai toujours les autres avant de faire quoi que ce soit pour moi-même. J'aime quand les autres ont besoin de moi.",
            3: "Je suis un gagnant. L'échec n'est pas une option pour moi et je m'efforce donc de réussir. En fait, je suis un bourreau de travail. Rester immobile n'est pas pour moi.",
            4: "J'ai des sautes d'humeur : un moment je peux être très heureux et le suivant je me sens incompris. La vie peut être dure. Les conversations superficielles ne sont pas pour moi : il doit y avoir de la profondeur dans mes relations avec les autres. Le pire serait que les autres me voient comme une souris grise ; Je veux vraiment faire la différence."
        },
        {
            5: "Je peux me concentrer sur le même sujet pendant longtemps s'il est dans mon domaine d'intérêt. Acquérir des connaissances sur des sujets qui m'intéressent est quelque chose sur lequel je travaille constamment – ​​après tout, on n'en sait jamais assez. Je prends littéralement du recul lorsque approché par une personne très arrogante. J'aime être seul et m'efforcer d'être indépendant.",
            6: "J'aime être préparé et j'aime apprendre. Je vais d'abord considérer toutes les options avant de prendre une décision. En cas de doute, je consulterai des personnes de mon environnement en qui j'ai confiance en l'opinion. On me traite parfois de prophète de malheur parce que je considère tous les scénarios possibles. Je veux de la clarté et savoir où j'en suis – la confiance est importante ; après tout, « nous sommes forts ensemble » !",
            7: "L'ennui ou la routine n'est pas pour moi. Donnez-moi juste de belles expériences, pouvoir exprimer de nouvelles idées et avoir de belles perspectives ! J'aime réfléchir et discuter des options. J'ai du mal à choisir car je préfère ne rien rater. Ce n'est pas un travail bien défini pour moi avec de nombreuses restrictions et règles. La vie doit être amusante !"
        }
    ]
};

export default texts;
