const texts = {
    explanation: `Je krijgt telkens een set van 3 afbeeldingen en woorden te zien.<br/>
    Klik deze afbeeldingen aan in de volgorde waarin ze jou meest aanspreken.`,
    button_start: 'Ga naar afbeeldingen',
    button_next: 'Volgende',
    button_again: 'Opnieuw',
    text_1: `spreekt mij <span className="important">meest</span> aan`,
    text_3: `spreekt mij <span className="important">minst</span> aan`,
};

export default texts;
