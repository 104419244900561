const texts = {
    explanation: `Indicate to what extent you recognize yourself in the following statements,<br />
    where 1 is least applicable and 5 is most applicable.<br />`,
    scale_left: "Disagree",
    scale_right: "Totally agree",
    button_next: "Next",
    button_start: "Go to the statements",

    questions: {
        1: "When I am unexpectedly called upon for an urgent assignment, I will carry it out as a priority.",
        2: "I adapt my way of working flexibly if this is expected of me.",
        3: "If I receive signals that indicate resistance in another person, I will automatically adjust my behavior.",
        4: "When there are changes in my schedule, I become unenjoyable.",
        5: "A new task gives me anxiety. I'd rather be prepared before I start anything.",

        6: "I realize what the consequences could be for others or for the organization if I did not complete my tasks with extreme precision.",
        7: "I am very observant. I see errors or imperfections that others fail to notice.",
        8: "I can focus on numbers for a long time and process them without making mistakes.",
        9: "I don't check my own work for errors. It must be well done, after all I have spent enough time on it.",
        10: "I occasionally make mistakes. Who not?",

        11: "When I am presented a problem, I will first collect all the information to find the cause and make connections with other issues.",
        12: "I arrive at a substantiated conclusion by including all perspectives in the process.",
        13: "When a problem situation arises, I will examine several relevant aspects and break the big picture down into sub-problems.",
        14: "I have a hard time distinguishing main from side issues.",
        15: "I don't have to analyze all the different approaches before formulating a conclusion.",

        16: "I make decisions easily, in any situation.",
        17: "I never procrastinate, even when I have to make difficult decisions.",
        18: "I don't shy away from problems and can easily make decisions.",
        19: "I often doubt and making quick decisions is difficult for me.",
        20: "Making unpopular decisions is not for me.",

        21: "I put the interests of the organization first before my own wishes.",
        22: "I support decisions made and will defend them both internally and externally.",
        23: "I strive to motivate colleagues with the purpose to achieve the common goal.",
        24: "I like to do my own thing and I am true to my own values ​​and beliefs.",
        25: "I can't say I'm proud of the organization I work for.",

        26: "I keep an eye on the progress of change processes and make adjustments where necessary.",
        27: "In change projects, I will prepare my decisions well and also communicate them constructively to those involved.",
        28: "I believe that every change requires good and transparent communication.",
        29: "I don't like goals or targets that change.",
        30: "In change projects I prefer to follow the manager than to take the initiative myself.",

        31: "I easily sense needs and adjust my strategy accordingly.",
        32: "I always follow the needs of my customers and create trust because that allows me to build long-term relationships.",
        33: "I can easily deal with resistance and quickly bend it in favor of my own products and/or services.",
        34: "I am not a salesperson; I don't like talking to strangers.",
        35: "Selling is not for me, I prefer not to force myself and / or my product on others.",

        36: "I choose the right words carefully so that I can convey my message clearly and purposefully.",
        37: "I manage to distinguish different opinions and get to the heart of the matter.",
        38: "I like to negotiate constructively – it is therefore satisfying to win in the end.",
        39: "Let others negotiate, I'll just do what I'm asked.",
        40: "I am strongly conflict avoidant and find it difficult to negotiate with others.",

        41: "I adapt my language to my conversation partner.",
        42: "I check with my conversation partner(s) whether my message has been properly understood before I move on to the next item.",
        43: "In professional communication I quickly get to the point and avoid having a conversation next to the issue.",
        44: "I prefer to communicate by email rather than face to face.",
        45: "I find it difficult to communicate my points of view.",

        46: "I can easily abstract from practice and can also approach a problem from a more theoretical framework.",
        47: "I can approach a problem well from a general context; I am good at generalizing.",
        48: "I can quickly see the consequences of a proposal.",
        49: "I must be able to do things; thinking in concepts is not for me.",
        50: "I'm very good at 'putting out fires'; better to solve quickly than to have to think (too) long.",

        51: "Even with problems that have arisen for a long time or repetitively, I can come up with new, refreshing solutions.",
        52: "Unexpected situations can happen anywhere; my talent for improvisation is very handy under those circumstances.",
        53: "I like to participate in brainstorming sessions; my contribution usually plays a decisive role in breaking away from routines.",
        54: "Brainstorming sessions cost me too much energy; I prefer to hear what has been decided afterwards.",
        55: "I'm not good at improvising. After all, preparation is half the battle!",

        56: "Even in difficult situations I don't give up.",
        57: "I will not be shaken by setbacks.",
        58: "I keep the end goal in mind and work towards it systematically, even when the going gets tough.",
        59: "If something gets too difficult or too enoying, I'm more likely to give up.",
        60: "A setback can upset me.",

        61: "I can easily turn negative employee behavior into positive actions.",
        62: "I am in favor of allowing the talents of my employees to develop optimally.",
        63: "I can encourage others to take action and involvement and thus achieve our goals together.",
        64: "I don't have much patience with people who want to deal with and figure out all the details of something.",
        65: "I just have my own leadership style. I don't adapt to every individual.",

        66: "I spontaneously see the potential of my employees and support them to grow in their position.",
        67: "I respect each individual and recognize their value in the bigger picture.",
        68: "I give my employees opportunities by delegating not only tasks but also responsibilities.",
        69: "I don't like to structure collaborations, I prefer to leave that to others.",
        70: "Because of the work load, I can't always keep to the agreements.",

        71: "I easily develop improvement proposals to arrive at a total solution.",
        72: "Development should never stand still.",
        73: "When developing new things, I also take into account my environment and market demand.",
        74: "Never change a winning team – you should not want to innovate things that are going well!",
        75: "Constantly making innovations often puts you backwards instead of forwards.",

        76: "I always put the customer first, the rest has to be adjusted accordingly.",
        77: "When looking for solutions, you should always consult with the customer before initiating actions.",
        78: "I take customer complaints very seriously. When dealing with complaints, I first and foremost ensure that the customer feels understood and helped.",
        79: "Customers should be delighted to be able to purchase our products and services!",
        80: "It is not because you are a good customer that you are entitled to extras.",

        81: "My assignments must be completed well, so that the customer is satisfied.",
        82: "I adhere to the imposed quality standards of the organization, even if this means that I have to go the extra mile.",
        83: "I continuously strive for optimal business processes that can increase the quality of the work.",
        84: "Quality is important but it is mainly quantity that puts money in the drawer.",
        85: "Control measures hold back qualitative output.",

        86: "I am open to feedback because I want to learn constantly.",
        87: "I see the weak points in my functioning and work consciously to improve them.",
        88: "I take every opportunity for self-development.",
        89: "It's not because you have a lot of experience that I have to adapt my working method to yours.",
        90: "Learning is interesting but exhausting; I would like to stay at the level where I am now. After all, there's nothing wrong with that.",

        91: "I easily pick up (important) messages from general conversations.",
        92: "I give my conversation partner the necessary space and attention.",
        93: "I let the other finish; I will never interrupt anyone.",
        94: "Half a word is usually enough to know what the other person will say next.",
        95: "Sometimes when I'm busy I pretend to listen.",

        96: "I am focused on opportunities and easily make contact with people who can help me.",
        97: "I use my network to obtain information.",
        98: "When there are novelties in the market, I am one of the first to be informed by my network.",
        99: "I hate networking events. I find it difficult to talk to people I don't know.",
        100: " Better a small number of valuable contacts than a whole list of superficial acquaintances.",

        101: "When I spot new opportunities, I go for it and I dare to take the necessary risks.",
        102: "I like to push boundaries.",
        103: "I am flexible and able to adapt to market demand.",
        104: "When it comes to investments, it is harder for me to make a decision.",
        105: "I don't know much about the competition; it is enough to know how our organization works.",

        106: "I make realistic plans.",
        107: "I look ahead, am proactive and anticipate on things.",
        108: "I can translate objectives into effective tasks and activities.",
        109: "I don't like organizing. I'll just do what's expected of me, once others have organized things.",
        110: "When many things have to be done at the same time, I sometimes lose the overview.",

        111: "I can name several examples where I have succeeded in influencing the opinion of others.",
        112: "When I have plans or ideas, I easily succeed in getting others involved.",
        113: "I manage to turn resistance into enthusiasm for my point of view and opinion.",
        114: "I don't like to argue.",
        115: "I find it difficult to convince others of my opinion when they have a different opinion.",

        116: "I seek opportunities to increase my own knowledge and improve my skills.",
        117: "When new methods need to be tried, I'm the first candidate.",
        118: "I feel connected to my job and the organization and that is why I can encourage other colleagues.",
        119: "I work to earn my living, that's all.",
        120: "To try new things, don't come to me. I'd rather wait for it to be tested by others.",

        121: "I carefully prepare for meetings and projects, both internally and with external partners.",
        122: "I anticipate possible bottlenecks or setbacks and anticipate them.",
        123: "I have a good understanding of how much time, resources and people are needed to achieve an agreed result.",
        124: "I don't deal well with unforeseen circumstances, because that messes up my planning.",
        125: "There's no need to plan ahead: I'll deal with things as they arise.",

        126: "I prevent things from getting out of hand by taking timely measures.",
        127: "I am able to thoroughly analyze a problem before proposing a solution.",
        128: "I don't focus on the problem but on the solution.",
        129: "I do not deal well with acute problem situations.",
        130: "I find it difficult to decide when there are different solutions to a problem.",

        131: "I stick to the agreements made with regard to the ultimate objective.",
        132: "Even if there is a problem, opposition or setback, I will always remain focused on the goal, without being distracted.",
        133: "You are only successful if you achieve the targets that were set.",
        134: "I don't need to know what end result my employer wants; I just do what is expected of me.",
        135: "I try to do what is expected of me. If it's not finished today, I can always continue tomorrow.",

        136: "My motto is: Together strong!",
        137: "I like to take over tasks from colleagues if this helps them.",
        138: "I actively contribute to or make proposals for a group task, even though I am not personally recognized for it.",
        139: "Working together is sometimes an obligation; I actually prefer to work alone.",
        140: "I don't like to take over from colleagues.",

        141: "I continue to perform well and effectively, even under time pressure.",
        142: "Even in crisis situations I keep a cool head and focus on the job.",
        143: "I am able, even in uncertain situations, to change my approach for the benefit of the result.",
        144: "I find it difficult to concentrate in unexpected crisis situations.",
        145: "Radiating calm in threatening circumstances is difficult for me.",

        146: "I am able to analyze complex technical problems and assess them effectively.",
        147: "With complex technical issues, I can quickly get clarity about cause and effect.",
        148: "I can easily formulate decisions based on the available technical data.",
        149: "I find it difficult to answer complex technical questions on the basis of limited information.",
        150: "It is not easy to make connections in complex technical processes.",

        151: "I am very critical when it comes to safety standards and follow them strictly.",
        152: "I set high standards for health and safety for myself and my colleagues.",
        153: "I hold others accountable if they do not follow the safety rules.",
        154: "I think safety rules are important, but in practice it is not always possible to follow them.",
        155: "Strict adherence to safety regulations makes it difficult to finish the job on time.",

        156: "I think outside the box and do not only consider the here and now.",
        157: "Drawing up policies and drawing up strategies for the future, that's what I'm good at.",
        158: "When formulating problems or solutions, I always take into account their impact on the future plans of the organization.",
        159: "I am not concerned with trends that could be important for the future of the organization.",
        160: "I am not concerned with the future interest of the organization.",

        161: "I can assess myself well at work and am always open to feedback from others.",
        162: "I think self-development is important and want to continuously improve my professional skills.",
        163: "I regularly do self-study because it is valuable for my own career.",
        164: "I find it difficult to receive feedback when it means I need to change something.",
        165: "I am open to learning things that will help me do my job better. When it comes to fine-tuning my personality, I find that difficult and actually at work this is none of there business.",

        166: "I can motivate myself well without needing others.",
        167: "I spontaneously ask for help when needed.",
        168: "When I do something wrong, I take responsibility for it myself.",
        169: "I don't like to ask for help from others, I will solve it myself, even if it may take a little longer.",
        170: "I sometimes look for a scapegoat when I've done something wrong that can have serious consequences.",

        171: "At work I strive for an atmosphere in which people dare to knock on each other's doors and in which everyone has respect for each other.",
        172: "I will always defend colleagues in public, but I do talk to my colleague about it when there are no others around.",
        173: "I don't talk about colleagues but with colleagues.",
        174: "I do not find it necessary to know the needs of my colleagues; after all, you are at work to do your job.",
        175: "Helping colleagues makes me have less time to do my own job, so I avoid that if I can.",

        176: "I can easily judge when I need to adjust my behavior in a certain situation.",
        177: "I can easily adjust priorities in changing circumstances.",
        178: "In case of sudden changes in assignments, I will adapt flexibly without going into resistance.",
        179: "It's harder for me to switch quickly when things change.",
        180: "I don't like it when I get an unexpected assignment."
    }
};

export default texts;
