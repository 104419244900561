import React from 'react';
import { Container } from 'react-bootstrap';

const Header = () => {

    return (

        <Container>
            &copy; {(new Date().getFullYear())}, Cowize
        </Container>

    );
}


export default Header;
