const texts = {
    explanation: `Indiquez de 1 à 5 dans la mesure dans laquelle cette déclaration s'applique à vous.<br />
    1 s'applique le moins et 5 s'applique le plus.<br />`,
    scale_left: "Pas du tout d'accord",
    scale_right: "Entièrement d'accord",
    button_next: "Suivante",
    button_start: "Continuer avec les déclarations",

    questions: {
        1: "Lorsque je suis sollicité de manière inattendue pour<br/>une mission urgente, je la réalise en priorité.",
        2: "J'adapte ma façon de travailler de manière flexible si cela est attendu de moi.",
        3: "Si je reçois des signaux qui indiquent une résistance chez une autre personne, j'ajusterai automatiquement mon comportement.",
        4: "Quand il y a des changements dans mon emploi du temps, je ne suis pas content.",
        5: "Une nouvelle tâche m'inquiète.<br/>Je préfère être préparé avant de commencer quoi que ce soit.",

        6: "Je réalise quelles pourraient être les conséquences pour les autres ou pour l'organisation si je n'accomplissais pas mes tâches avec une extrême précision.",
        7: "Je suis très observateur. Je vois des erreurs ou des imperfections que les autres ne remarquent pas.",
        8: "Je peux me concentrer longtemps sur les nombres et les traiter sans faire d'erreurs.",
        9: "Je ne vérifie pas mon propre travail pour les erreurs. Il doit être bien fait, après tout j'ai passé assez de temps dessus.",
        10: "Je fais parfois des erreurs. Qui ne les fait pas?",

        11: "Quand je formule un problème, je vais d'abord collecter toutes les informations pour trouver la cause et faire des liens avec d'autres choses.",
        12: "J'arrive à une conclusion fondée en incluant toutes les perspectives dans le processus.",
        13: "Lorsqu'une situation problématique survient, j'examinerai plusieurs aspects pertinents et je diviserai la vue d'ensemble en sous-problèmes.",
        14: "J'ai du mal à distinguer les problèmes principaux des problèmes secondaires.",
        15: "Je n'ai pas besoin d'analyser toutes les différentes approches avant de pouvoir formuler une conclusion.",

        16: "Je prends des décisions facilement, dans n'importe quelle situation.",
        17: "Je ne procrastine jamais, même lorsque je dois prendre des décisions difficiles.",
        18: "Je n'ai pas peur des problèmes et je peux facilement prendre des décisions.",
        19: "J'ai souvent des doutes et prendre des décisions rapides est difficile pour moi.",
        20: "Prendre des décisions impopulaires n'est pas pour moi.",

        21: "Je fais passer les intérêts de l'organisation avant mes propres souhaits.",
        22: "Je soutiens les décisions prises et les défendrai tant en interne qu'en externe.",
        23: "Je m'efforce de motiver mes collègues en vue d'atteindre l'objectif commun.",
        24: "J'aime faire mon propre truc et je suis fidèle à mes propres valeurs et croyances.",
        25: "Je ne peux pas dire que je suis fier de l'organisation pour laquelle je travaille.",

        26: "Je garde un œil sur l'avancement des processus de changement et j'apporte des ajustements si nécessaire.",
        27: "Dans les projets de changement, je préparerai bien mes décisions et les communiquerai également de manière constructive aux personnes impliquées.",
        28: "Je crois que chaque changement nécessite une communication bonne et transparente.",
        29: "Je n'aime pas les objectifs qui changent.",
        30: "Dans les projets de changement, je préfère suivre le manager que de prendre l'initiative moi-même.",

        31: "Je sens facilement les besoins et ajuste ma stratégie en conséquence.",
        32: "Je suis toujours à l'écoute des besoins de mes clients et crée la confiance car cela me permet de nouer des relations à long terme.",
        33: "Je peux facilement faire face à la résistance et la plier rapidement en faveur de mes propres produits et/ou services.",
        34: "je ne suis pas un vendeur ; Je n'aime pas parler aux étrangers.",
        35: "Vendre n'est pas pour moi,<br/>je préfère ne pas m'imposer et/ou imposer mon produit aux autres.",

        36: "Je choisis les bons mots avec soin afin de pouvoir transmettre mon message de manière claire et ciblée.",
        37: "J'arrive à distinguer les différentes opinions et à entrer dans le vif du sujet.",
        38: "J'aime négocier de manière constructive – c'est donc une satisfaction de gagner au final.",
        39: "Laissez les autres négocier, je ferai simplement ce qu'on me demande.",
        40: "Je évite fortement les conflits et j'ai du mal à négocier avec les autres.",

        41: "J'adapte mon langage à mon interlocuteur.",
        42: "Je vérifie auprès de mon ou mes interlocuteurs si mon message a été bien compris avant de passer au point suivant.",
        43: "Dans la communication professionnelle, j'arrive rapidement à l'essentiel et j'évite d'avoir une conversation qui va au-delà du problème.",
        44: "Je préfère communiquer par email plutôt qu'en face à face.",
        45: "J'ai du mal à exprimer mon point de vue.",

        46: "Je peux facilement faire abstraction de la pratique et je peux également aborder un problème à partir d'un cadre plus théorique.",
        47: "Je peux bien aborder un problème à partir d'un contexte général ; Je suis doué pour généraliser.",
        48: "Je peux voir rapidement les conséquences d'une proposition.",
        49: "Je dois être capable de faire des choses ; penser en concepts n'est pas pour moi.",
        50: "Je suis très doué pour éteindre les incendies ; mieux vaut résoudre vite que réfléchir (trop) longtemps.",

        51: "Je peux également proposer des solutions nouvelles et rafraîchissantes pour des problèmes qui surviennent depuis longtemps ou de manière répétitive.",
        52: "Des situations inattendues peuvent se produire n'importe où ; mon talent pour l'improvisation est très pratique.",
        53: "J'aime participer à des séances de brainstorming ; ma contribution joue généralement un rôle décisif dans la rupture avec les routines.",
        54: "Les séances de brainstorming me coûtent trop d'énergie ; Je préfère entendre ce qui a été décidé après coup.",
        55: "Je ne suis pas doué pour l'improvisation. Après tout, la préparation est la moitié de la bataille !",

        56: "Je n'abandonne pas même dans les situations difficiles.",
        57: "Je ne me laisserai pas berner par les revers.",
        58: "Je garde l'objectif final à l'esprit et y travaille systématiquement, même lorsque les choses se corsent.",
        59: "Si quelque chose devient trop difficile ou trop difficile, je suis plus susceptible d'abandonner.",
        60: "Un échec peut me bouleverser.",

        61: "Je peux facilement transformer le comportement négatif des employés en actions positives.",
        62: "Je suis favorable à ce que les talents de mes collaborateurs se développent de manière optimale.",
        63: "Je peux stimuler les autres à agir et à s'impliquer et ainsi atteindre nos objectifs ensemble.",
        64: "J'ai peu de patience avec les gens qui veulent passer en revue tous les détails de quelque chose.",
        65: "J'ai juste mon propre style de leadership. Je ne m'adapte pas à chaque individu.",

        66: "Je vois spontanément les opportunités d'évolution de mes collaborateurs et les accompagne pour évoluer dans leur poste.",
        67: "Je respecte chaque individu et reconnais sa valeur dans l'ensemble.",
        68: "Je donne des opportunités à mes collaborateurs, en leur déléguant non seulement des tâches mais aussi des responsabilités.",
        69: "Je n'aime pas structurer les collaborations, je préfère laisser ça aux autres.",
        70: "À cause de la foule, je ne peux pas toujours tenir les accords.",

        71: "Je développe facilement des propositions d'amélioration pour arriver à une solution globale.",
        72: "Le développement ne doit jamais s'arrêter.",
        73: "Lors du développement de nouvelles affaires, je prends également en compte mon environnement et la demande du marché.",
        74: "Ne changez jamais une équipe gagnante – vous ne devriez pas vouloir innover des choses qui vont bien !",
        75: "Innover constamment vous fait souvent reculer au lieu d'avancer.",

        76: "Je mets toujours le client en premier, le reste doit être ajusté en conséquence.",
        77: "Lors de la recherche de solutions, vous devez toujours consulter le client avant d'engager des actions.",
        78: "Je prends les plaintes des clients très au sérieux. Lors du traitement des réclamations, je m'assure que le client se sente compris et aidé en premier lieu.",
        79: "Les clients sont ravis de pouvoir acheter nos produits et services !",
        80: "Ce n'est pas parce que vous êtes un bon client que vous avez droit à des extras.",

        81: "Mes missions doivent être bien remplies, pour que le client soit satisfait.",
        82: "J'adhère aux normes de qualité imposées par l'organisation, même si cela signifie que je dois faire un effort supplémentaire.",
        83: "Je m'efforce continuellement d'obtenir des processus commerciaux optimaux qui peuvent augmenter la qualité du travail.",
        84: "La qualité est importante, mais c'est surtout la quantité qui fait de l'argent dans le tiroir.",
        85: "Les mesures de contrôle freinent les résultats qualitatifs.",

        86: "Je suis ouvert aux retours car je veux apprendre en permanence.",
        87: "Je vois les points faibles de mon fonctionnement et travaille consciemment pour les améliorer.",
        88: "Je profite de toutes les occasions pour m'épanouir.",
        89: "Ce n'est pas parce que tu as beaucoup d'expérience que je dois adapter ma méthode de travail à la tienne.",
        90: "L'apprentissage est intéressant mais épuisant ; J'aime donc rester au niveau où je suis actuellement, il n'y a rien de mal à cela.",

        91: "Je capte facilement les messages (importants) des conversations générales.",
        92: "Je donne à mon interlocuteur l'espace et l'attention nécessaires.",
        93: "Je laisse l'autre finir; Je n'interromprai jamais personne.",
        94: "Un demi-mot suffit généralement pour savoir ce que l'autre personne dira ensuite.",
        95: "Quand je suis occupé, je fais parfois semblant d'écouter.",

        96: "Je suis concentré sur les opportunités et je prends facilement contact avec les personnes qui peuvent m'aider.",
        97: "J'utilise mon réseau pour obtenir des informations.",
        98: "Lorsqu'il y a des nouveautés sur le marché,<br/>je suis l'un des premiers à être informé par mon réseau.",
        99: "Je déteste les événements de réseautage. J'ai du mal à parler à des gens que je ne connais pas.",
        100: "Plutôt un petit nombre de contacts précieux qu'une liste entière de connaissances superficielles.",

        101: "Quand je repère de nouvelles opportunités, je fonce et j'ose prendre les risques nécessaires.",
        102: "J'aime repousser les limites.",
        103: "Je suis flexible et capable de m'adapter à la demande du marché.",
        104: "En matière d'investissements, il m'est plus difficile de prendre une décision.",
        105: "Je ne connais pas grand-chose à la compétition ; il suffit de savoir comment fonctionne notre organisation.",

        106: "Je fais des plans réalistes.",
        107: "Je regarde vers l'avenir, je suis proactif et j'anticipe les affaires.",
        108: "Je peux traduire les objectifs en tâches et activités efficaces.",
        109: "Je n'aime pas organiser. Je ferai juste ce qu'on attend de moi, une fois que les autres auront organisé les choses.",
        110: "Quand beaucoup de choses doivent être faites en même temps, je perds parfois la vue d'ensemble.",

        111: "Je peux citer plusieurs exemples où j'ai réussi à influencer l'opinion des autres.",
        112: "Quand j'ai des projets ou des idées, je réussis facilement à impliquer les autres.",
        113: "J'arrive à transformer la résistance en enthousiasme pour mon point de vue et mon opinion.",
        114: "Je n'aime pas discuter.",
        115: "J'ai du mal à convaincre les autres de mon opinion lorsqu'ils ont une opinion différente.",

        116: "Je recherche des opportunités d'accroître mes propres connaissances et d'améliorer mes compétences.",
        117: "Quand de nouvelles méthodes doivent être essayées, je suis le premier candidat.",
        118: "Je me sens connecté à mon travail et à l'organisation et c'est pourquoi je peux encourager d'autres collègues.",
        119: "Je travaille pour gagner ma vie, c'est tout.",
        120: "Pour essayer de nouvelles choses, ne soyez pas avec moi. Je préfère attendre qu'il soit testé par d'autres.",

        121: "Je prépare soigneusement les réunions et les projets, aussi bien en interne qu'avec des partenaires externes.",
        122: "J'anticipe les éventuels goulots d'étranglement ou contretemps et je les anticipe.",
        123: "Je comprends bien combien de temps, de ressources et de personnes sont nécessaires pour obtenir un résultat convenu.",
        124: "Je ne gère pas bien les circonstances imprévues, car cela perturbe ma planification.",
        125: "Il n'y a pas besoin de planifier à l'avance : je traiterai les choses au fur et à mesure qu'elles se présenteront.",

        126: "J'empêche les choses de devenir incontrôlables en prenant des mesures opportunes.",
        127: "Je suis capable d'analyser en profondeur un problème avant de proposer une solution.",
        128: "Je ne me concentre pas sur le problème mais sur la solution.",
        129: "Je ne suis pas doué pour gérer les situations problématiques aiguës.",
        130: "J'ai du mal à décider quand il y a différentes solutions à un problème.",

        131: "Je m'en tiens aux accords conclus quant à l'objectif final.",
        132: "Même s'il y a un problème, une opposition ou un contretemps, je resterai toujours concentré sur le but, sans être distrait.",
        133: "Vous ne réussissez que si vous atteignez les résultats qui ont été fixés.",
        134: "Je n'ai pas besoin de savoir quel résultat final veut mon employeur ; Je fais juste ce qu'on attend de moi.",
        135: "J'essaie de faire ce qu'on attend de moi. Si ce n'est pas fini aujourd'hui, je peux toujours continuer demain.",

        136: "Ma devise est : on est forts ensemble !",
        137: "J'aime reprendre les tâches de mes collègues si cela les aide.",
        138: "Je contribue activement ou fais des propositions pour une tâche de groupe, même si je ne suis pas personnellement reconnu pour cela.",
        139: "Travailler ensemble est parfois une obligation ; En fait, je préfère travailler seul.",
        140: "Je n'aime pas reprendre les tâches de mes collègues.",

        141: "Je continue d'être performant et efficace, même sous la pression du temps.",
        142: "Même dans les situations de crise, je garde la tête froide et je me concentre sur mon travail.",
        143: "Je suis capable, même dans des situations incertaines, de changer d'approche au profit du résultat.",
        144: "J'ai du mal à me concentrer dans des situations de crise inattendues.",
        145: "Diffuser le calme dans des circonstances menaçantes est difficile pour moi.",

        146: "Je suis capable d'analyser des problèmes techniques complexes et de les évaluer efficacement.",
        147: "Avec des problèmes techniques complexes, je peux rapidement obtenir des éclaircissements sur la cause et l'effet.",
        148: "Je peux facilement formuler des décisions sur la base des données techniques disponibles.",
        149: "J'ai du mal à répondre à des questions techniques complexes sur la base d'informations limitées.",
        150: "Il n'est pas facile d'établir des liens dans des processus techniques complexes.",

        151: "Je suis très critique en ce qui concerne les normes de sécurité et je les respecte strictement.",
        152: "Je me fixe des normes élevées en matière de santé et de sécurité pour moi-même et mes collègues.",
        153: "Je tiens les autres pour responsables s'ils ne respectent pas les règles de sécurité.",
        154: "Je pense que les règles de sécurité sont importantes mais en pratique il n'est pas toujours possible de les suivre.",
        155: "Le strict respect des règles de sécurité rend difficile de terminer le travail à temps.",

        156: "Je pense en dehors des sentiers battus et ne considère pas seulement l'ici et maintenant.",
        157: "Élaborer des politiques et élaborer des stratégies pour l'avenir, c'est ce pour quoi je suis doué.",
        158: "Lorsque je formule des problèmes ou des solutions, je prends toujours en compte leur impact sur les plans futurs de l'organisation.",
        159: "Je ne suis pas concerné par les tendances qui pourraient être importantes pour l'avenir de l'organisation.",
        160: "Je ne suis pas concerné par l'intérêt futur de l'organisation.",

        161: "Je peux bien m'évaluer au travail et je suis toujours ouvert aux commentaires des autres.",
        162: "Le développement personnel est important pour moi et je veux améliorer mes compétences professionnelles tout le temps.",
        163: "Je fais régulièrement de l'auto-apprentissage parce que c'est précieux pour ma propre carrière.",
        164: "J'ai du mal à recevoir des commentaires lorsque cela signifie que je dois changer quelque chose.",
        165: "Je suis ouvert à apprendre des choses qui m'aideront à mieux faire mon travail. Quand il s'agit d'affiner ma personnalité, je trouve cela difficile et en fait, ils n'ont rien à faire avec ça au travail.",

        166: "Je peux bien me motiver sans avoir besoin des autres.",
        167: "Je demande spontanément de l'aide en cas de besoin.",
        168: "Si je fais quelque chose de mal, j'en assume la responsabilité moi-même.",
        169: "Je n'aime pas demander de l'aide aux autres, je vais le résoudre moi-même, même si cela peut prendre un peu plus de temps.",
        170: "Je cherche parfois un bouc émissaire lorsque j'ai fait quelque chose de mal qui peut avoir de graves conséquences.",

        171: "Au travail, je m'efforce de créer une atmosphère dans laquelle les gens osent frapper à la porte des autres et dans laquelle chacun se respecte.",
        172: "Je défendrai toujours mes collègues en public, mais j'en parle à mon collègue quand il n'y en a pas d'autres autour.",
        173: "Je ne parle pas de collègues mais avec des collègues.",
        174: "Je n'estime pas nécessaire de connaître les besoins de mes collègues ; après tout, vous êtes au travail pour faire votre travail.",
        175: "Aider mes collègues me fait avoir moins de temps pour faire mon propre travail, alors j'évite cela si je le peux.",

        176: "Je peux facilement juger quand je dois ajuster mon comportement dans une certaine situation.",
        177: "Je peux facilement ajuster les priorités dans des circonstances changeantes.",
        178: "En cas de changements brusques d'affectations, je m'adapterai avec souplesse sans entrer dans la résistance.",
        179: "C'est plus difficile pour moi de changer rapidement lorsque les choses changent.",
        180: "Je n'aime pas quand je reçois une mission inattendue.",
    }
}

export default texts;
