const texts = {
    explanation: `Geef aan in welke mate je jezelf in volgende uitspraken
    herkent,
    <br />
    waarbij 1 minst van toepassing en 5 meest van toepassing
    is.
    <br />`,
    scale_left: "Helemaal niet eens",
    scale_right: "Helemaal eens",
    button_next: "Volgende",
    button_start: "Ga naar uitspraken",

    questions: {
        1: "Wanneer er op mij onverwachts een beroep wordt gedaan voor een dringende opdracht,<br/>zal ik deze als prioriteit uitvoeren.",
        2: "Ik pas mijn manier van werken flexibel aan als dit van mij verwacht wordt.",
        3: "Als ik signalen opvang die op weerstand duiden bij een ander,<br/>zal ik mijn gedrag vanzelf aanpassen.",
        4: "Als er zich veranderingen voordoen in mijn planning,<br/>ben ik niet te genieten.",
        5: "Een nieuwe taak bezorgt mij onrust.<br/>Ik ben liever voorbereid voordat ik aan iets begin.",
        6: "Ik besef wat de gevolgen kunnen zijn voor anderen of voor de organisatie<br/>als ik mijn taken niet buitengewoon nauwkeurig zou afwerken.",
        7: "Ik ben zeer opmerkzaam.<br/>Ik zie fouten of onvolkomendheden die anderen niet opmerken.",
        8: "Ik kan mij gedurende lange tijd concentreren op cijfers<br/>en deze verwerken zonder fouten te maken.",
        9: "Ik controleer mijn eigen werk niet op fouten.<br/>Het zal wel goed gedaan zijn, ik heb er immers voldoende tijd aan besteed.",
        10: "Ik maak af en toe fouten. Wie niet?",
        11: "Bij een probleemstelling zal ik eerst alle informatie verzamelen<br/>om de oorzaak te vinden en verbanden te leggen met andere zaken.",
        12: "Ik kom tot een onderbouwde conclusie door alle invalshoeken mee te nemen in het proces.",
        13: "Als er zich een probleemsituatie voordoet,<br/>zal ik verschillende relevante aspecten onderzoeken en het grote geheel opsplitsen in deelproblemen.",
        14: "Ik heb het moeilijk om hoofd- van bijzaken te onderscheiden.",
        15: "Ik hoef niet alle verschillende benaderingen te analyseren<br/>vooraleer een conclusie te kunnen formuleren.",
        16: "Ik neem makkelijk beslissingen, in elke situatie.",
        17: "Ik vertoon nooit uitstelgedrag,<br/>ook niet als ik moeilijke beslissingen moet nemen.",
        18: "Ik ga problemen niet uit de weg en kan gemakkelijk knopen doorhakken.",
        19: "Ik twijfel vaak en snelle beslissingen nemen is dan ook moeilijk voor mij. ",
        20: "Onpopulaire beslissingen nemen, is niet aan mij besteed.",
        21: "Ik stel het belang van de organisatie voorop aan mijn eigen wensen.",
        22: "Ik steun genomen beslissingen en zal ze zowel intern als extern ook verdedigen.",
        23: "Ik streef ernaar collega's te motiveren met het oog op het behalen van het gezamenlijk doel.",
        24: "Ik doe graag mijn eigen ding en ben trouw aan mijn eigen waarden en overtuigingen.",
        25: "Ik kan niet zeggen dat ik fier ben op de organisatie waarvoor ik werk.",
        26: "Ik hou de voortgang van veranderingsprocessen in het oog en stuur bij waar nodig.",
        27: "Bij veranderingsprojecten, zal ik mijn besluiten goed voorbereiden<br/>en deze ook constructief overbrengen op de betrokkenen.",
        28: "Ik vind dat elke verandering een goede en transparante communicatie vergt.",
        29: "Ik hou niet van doelstellingen die wijzigen.",
        30: "Bij veranderingsprojecten volg ik liever de manager dan zelf initiatief te nemen.",
        31: "Ik voel makkelijk behoeftes aan en pas mijn strategie daaraan aan.",
        32: "Ik ga altijd af op de behoefte van mijn klanten en creëer vertrouwen<br/>want dat stelt mij in staat lange termijn relaties op te bouwen.",
        33: "Ik kan gemakkelijk omgaan met weerstand en buig deze al snel om ten voordele van de eigen producten en / of diensten.",
        34: "Ik ben geen verkoper; ik praat niet graag met vreemden.",
        35: "Verkopen is niets voor mij, ik dring mijzelf en / of mijn product liever niet op aan anderen.",
        36: "Ik kies zorgvuldig de juiste woorden zodat ik mijn boodschap helder en doelgericht kan overbrengen.",
        37: "Ik slaag erin verschillende meningen van elkaar te onderscheiden en terug te komen tot de kern van de zaak.",
        38: "Ik voer graag constructieve onderhandelingen - het geeft dan ook voldoening om aan het eind te winnen.",
        39: "Laat anderen maar onderhandelen, ik doe wel gewoon wat mij gevraagd wordt.",
        40: "Ik ben sterk conflict vermijdend en vind het moeilijk met anderen over iets te onderhandelen.",
        41: "Ik stem mijn taalgebruik af op mijn gesprekspartner.",
        42: "Ik check bij mijn gesprekspartner(s) of mijn boodschap goed begrepen is vooraleer ik overga naar een volgend item.",
        43: "In professionele communicatie kom ik snel to the point en vermijd ik dat een gesprek gevoerd wordt naast de kwestie.",
        44: "Ik communiceer liever per mail dan face to face.",
        45: "Ik vind het moeilijk mijn standpunten over te brengen.",
        46: "Ik kan makkelijk abstractie maken van de praktijk en kan een probleem ook vanuit een meer theoretisch kader benaderen.",
        47: "Een probleem kan ik goed benaderen vanuit een algemene context; ik ben goed in het generaliseren.",
        48: "Ik kan de consequenties van een voorstel snel inzien.",
        49: "Ik moet dingen kunnen doen; denken in concepten is niets voor mij.",
        50: "Ik ben heel goed in 'brandjes blussen'; beter snel kunnen oplossen dan (te) lang moeten nadenken.",
        51: "Ook bij problemen die zich al lang of repetitief voordoen, kan ik met nieuwe verfrissende oplossingen komen.",
        52: "Onverwachte situaties kunnen zich overal voordoen; mijn improvisatietalent is dan zeer handig.",
        53: "Ik doe graag mee aan brainstormsessies; mijn bijdrage speelt meestal een bepalende rol om los te komen van routines.",
        54: "Brainstormsessies kosten mij teveel energie; ik hoor liever achteraf wat beslist is.",
        55: "Ik ben niet goed in improviseren.Voorbereiding is immers het halve werk!",
        56: "Ook in moeilijke situaties geef ik niet op.",
        57: "Ik laat mij niet van de wijs brengen door tegenslagen.",
        58: "Ik hou het einddoel voor ogen en werk daar systematisch naar toe, ook als het wat moeilijker gaat.",
        59: "Als iets te moeilijk of te lastig wordt, zal ik sneller geneigd zijn om op te geven.",
        60: "Een tegenslag kan mij van mijn stuk brengen.",
        61: "Ik kan negatief gedrag van medewerkers makkelijk ombuigen tot positieve acties.",
        62: "Ik ben voorstander om de talenten van mijn medewerkers zich optimaal te laten ontwikkelen.",
        63: "Ik kan anderen stimuleren tot actie en betrokkenheid en op die manier gezamenlijk onze doelen bereiken.",
        64: "Ik heb weinig geduld met mensen die alle details van iets willen behandelen en uitpluizen.",
        65: "Ik heb nu eenmaal mijn eigen leiderschapsstijl.Ik pas mij niet aan elk individu aan.",
        66: "Ik zie spontaan de ontwikkelingsmogelijkheden van mijn medewerkers en ondersteun hen om te groeien in hun functie.",
        67: "Ik heb respect voor elk individu en erken hun waarde in het grotere geheel.",
        68: "Ik geef mijn medewerkers kansen, door niet alleen taken maar ook verantwoordelijkheden te delegeren.",
        69: "Ik hou er niet van structuur te brengen in samenwerkingsverbanden, dat laat ik liever over aan anderen.",
        70: "Door de drukte kan ik mij niet altijd aan de afspraken houden.",
        71: "Ik ontwikkel makkelijk verbetervoorstellen om tot een totaaloplossing te komen.",
        72: "Ontwikkeling mag nooit stil staan.",
        73: "Bij het ontwikkelen van nieuwe zaken,<br/>hou ik ook rekening met mijn omgeving en de vraag van de markt.",
        74: "Never change a winning team - je moet zaken die goed lopen, niet willen innoveren!",
        75: "Constant vernieuwingen aanbrengen,<br/>zet je dikwijls achteruit i.p.v. vooruit.",
        76: "Ik stel de klant altijd centraal,<br/>de rest moet daarop aangepast worden.",
        77: "Bij het zoeken naar oplossingen,<br/>moet je altijd eerst met de klant overleggen vooraleer acties te initiëren.",
        78: "Klachten van klanten neem ik zeer serieus. Bij klachten verwerking,<br/>zorg ik er in de eerste plaats voor dat de klant zich begrepen en geholpen voelt.",
        79: "Klanten mogen blij dat zij onze producten en diensten kunnen kopen!",
        80: "Het is niet omdat je een goede klant bent, dat je recht hebt op extra's. ",
        81: "Mijn opdrachten moeten goed afgewerkt zijn,<br/>zodat de klant tevreden is.",
        82: "Ik hou mij aan de opgelegde kwaliteitsnormen van de organisatie, ook al betekent dit dat ik een tandje moet bijsteken.",
        83: "Ik streef continu naar optimale bedrijfsprocessen die de kwaliteit van de werkzaamheden kunnen verhogen.",
        84: "Kwaliteit is belangrijk maar het is vooral kwantiteit die zorgt voor geld in het laadje.",
        85: "Controlemaatregelen houden kwalitatieve output tegen.",
        86: "Ik sta open voor feedback omdat ik constant wil bijleren.",
        87: "Ik zie zelf de zwakkere punten in mijn functioneren en werk er bewust aan deze te verbeteren.",
        88: "Ik benut elke kans op zelfontwikkeling.",
        89: "Het is niet omdat je veel ervaring hebt, dat ik mijn werkwijze moet aanpassen aan die van jou.",
        90: "Bijleren is interessant maar vermoeiend; ik blijf dan ook graag op het niveau waar ik nu sta.Daar is immers niets mis mee.",
        91: "Ik pik gemakkelijk(belangrijke) boodschappen uit algemene gesprekken.",
        92: "Ik geef mijn gesprekspartner de nodige ruimte en aandacht.",
        93: "Ik laat de ander uitpraten; ik zal nooit iemand onderbreken.",
        94: "Aan een half woord heb ik meestal genoeg om te weten wat de ander verder nog zal zeggen.",
        95: "Als ik druk bezig ben, doe ik soms alsof ik luister.",
        96: "Ik ben gericht op opportuniteiten en leg makkelijk contact met mensen die mij kunnen helpen.",
        97: "Ik benut mijn netwerk voor het verkrijgen van informatie.",
        98: "Als er nieuwigheden zijn in de markt, word ik als één van de eersten ingelicht door mijn netwerk.",
        99: "Ik haat netwerkevents. Praten met mensen die ik niet ken, vind ik immers moeilijk.",
        100: "Liever een klein aantal waardevolle contacten dan een hele lijst oppervlakkige kennissen.",
        101: "Als ik nieuwe kansen spot, ga ik er voor en durf ik de nodige risico's nemen.",
        102: "Ik ben graag grensverleggend bezig.",
        103: "Ik ben flexibel en in staat mij aan te passen aan de marktvraag.",
        104: "Als het gaat om investeringen, kom ik moeilijker tot een besluit.",
        105: "Ik weet niet veel van de concurrentie; het is voldoende te weten hoe onze organisatie werkt.",
        106: "Ik maak realistische plannen op.",
        107: "Ik kijk vooruit, ben pro - actief en anticipeer op zaken.",
        108: "Ik kan doelstellingen vertalen naar effectieve taken en activiteiten.",
        109: "Ik hou niet van organiseren. Ik doe wel gewoon wat van mij verwacht wordt,<br/>eens dat anderen de zaken georganiseerd hebben.",
        110: "Als er veel zaken tegelijk gedaan moeten worden, verlies ik wel eens het overzicht.",
        111: "Ik kan meerdere voorbeelden benoemen waar ik erin geslaagd ben de mening van anderen te beïnvloeden.",
        112: "Als ik plannen of ideeën heb, slaag ik er makkelijk in anderen daarin mee te krijgen.",
        113: "Ik slaag erin weerstand om te buigen naar enthousiasme voor mijn standpunt en mening.",
        114: "Ik argumenteer niet graag.",
        115: "Ik vind het moeilijk om anderen te overtuigen van mijn mening wanneer zij er een andere mening op na houden.",
        116: "Ik zoek kansen om mijn eigen kennis te vergroten en mijn vaardigheden te verbeteren.",
        117: "Als er nieuwe methoden uitgeprobeerd moeten worden, ben ik als eerste kandidaat.",
        118: "Ik voel mij verbonden met mijn job en de organisatie en dat is de reden dat ik andere collega’s kan stimuleren.",
        119: "Ik werk om mijn boterham te verdienen, meer niet.",
        120: "Om nieuwe zaken uit te proberen, moet je niet bij mij zijn.Ik wacht liever tot het uitgetest is door anderen.",
        121: "Ik bereid mij zorgvuldig voor op meetings en projecten, zowel intern als met externe partners.",
        122: "Ik anticipeer mogelijke knelpunten of tegenslagen en voorzie mij daar op.",
        123: "Ik heb een goed inzicht in hoeveel tijd, middelen en mensen er nodig zijn om een afgesproken resultaat te halen.",
        124: "Ik kan niet goed omgaan met onvoorziene omstandigheden,<br/>dat stuurt immers mijn planning in de war.",
        125: "Vooruit plannen is nergens voor nodig: ik pak de dingen wel aan als ze zich voordoen.",
        126: "Ik voorkom dat zaken uit de hand lopen door tijdig maatregelen te nemen.",
        127: "Ik ben in staat een probleem grondig te analyseren vooraleer een oplossing voor te stellen.",
        128: "Ik richt mij niet op het probleem maar op de oplossing.",
        129: "Ik kan niet goed omgaan met acute probleemsituaties.",
        130: "Ik vind het moeilijk te beslissen als er verschillende oplossingen zijn voor een probleem.",
        131: "Ik houd mij aan de gemaakte afspraken m.b.t. de uiteindelijke doelstelling.",
        132: "Ook als er een probleem, tegenkanting of tegenslag is, zal ik mij altijd blijven richten op het doel, zonder mij te laten afleiden.",
        133: "Je bent pas succesvol als je de resultaten behaalt die vooropgesteld werden.",
        134: "Ik hoef niet te weten welk eindresultaat mijn werkgever vooropstelt; ik doe gewoon wat van mij verwacht wordt.",
        135: "Ik probeer te doen wat van mij verwacht wordt. Als het vandaag niet af is, kan ik morgen altijd verder doen.",
        136: "Mijn motto is: Samen sterk!",
        137: "Ik neem graag taken over van collega's als zij daarmee geholpen zijn.",
        138: "Ik lever actieve bijdragen aan of doe voorstellen voor een groepstaak, ook al word ik daar persoonlijk niet voor erkend.",
        139: "Samenwerken is soms een verplichting; ik werk eigenlijk liever alleen.",
        140: "Ik hou er niet van taken over te nemen van collega's.",
        141: "Ik blijf goed en effectief presteren, ook onder tijdsdruk.",
        142: "Ook in crisissituaties hou ik het hoofd koel en mijn aandacht bij de job.",
        143: "Ik ben in staat, ook in onzekere situaties, om mijn aanpak te wijzigen ten voordele van het resultaat.",
        144: "Bij onverwachte crisissituaties kan ik mij nog moeilijk concentreren.",
        145: "Rust uitstralen in bedreigende omstandigheden is moeilijk voor mij.",
        146: "Ik ben in staat om analyses te maken van complexe technische problemen en deze effectief te beoordelen.",
        147: "Bij complexe technische issues, kan ik snel duidelijkheid krijgen omtrent oorzaak en gevolg.",
        148: "Op basis van de beschikbare technische gegevens kan ik makkelijk besluiten formuleren.",
        149: "Ik vind het moeilijk op basis van beperkte informatie, complexe technische vraagstukken te beantwoorden.",
        150: "Het is niet eenvoudig verbanden te leggen bij complexe technische processen.",
        151: "Ik ben heel kritisch als het gaat om veiligheidsnormen en volg die dan ook strikt.",
        152: "Ik stel hoge eisen aan veiligheids - en gezondheidsaspecten voor mij en mijn collega's.",
        153: "Ik spreek anderen erop aan als ze zich niet aan de veiligheidsregels houden.",
        154: "Ik vind veiligheidsregels belangrijk maar het is in de praktijk niet altijd mogelijk ze te volgen.",
        155: "Strikt opvolgen van het veiligheidsreglement maakt het moeilijk om de job tijdig af te hebben.",
        156: "Ik denk buiten het alledaagse kader en hou niet alleen rekening met het hier en nu.",
        157: "Beleidslijnen opmaken en strategieën opstellen voor de toekomst, daar ben ik nu eenmaal goed in.",
        158: "Bij probleemstellingen of oplossingen houd ik altijd rekening met de impact ervan op de toekomstplannen van de organisatie.",
        159: "Ik houd mij niet bezig met trends die belangrijk zouden kunnen zijn voor de toekomst van de organisatie.",
        160: "Ik ben niet bezig met het toekomstbelang van de organisatie.",
        161: "Ik kan mijzelf goed inschatten op werkvlak en sta altijd open voor feedback van anderen.",
        162: "Ik vind zelfontwikkeling belangrijk en wil mijn professionele vaardigheden steeds verbeteren.",
        163: "Ik doe regelmatig aan zelfstudie omdat dit waardevol is voor mijn eigen loopbaan.",
        164: "Ik vind het moeilijk om feedback te ontvangen als deze inhoudt dat ik iets moet veranderen.",
        165: "Ik sta ervoor open om zaken te leren waardoor ik mijn job beter kan uitvoeren.<br />Als het gaat over het bijschaven van mijn persoonlijkheid, vind ik dat lastig en eigenlijk hebben ze daar op mijn werk geen zaken mee.",
        166: "Ik kan mezelf goed motiveren zonder daar anderen voor nodig te hebben.",
        167: "Ik vraag spontaan om hulp als dat nodig is.",
        168: "Als ik iets fout doe, neem ik daar zelf de verantwoordelijkheid voor.",
        169: "Ik hou er niet van hulp te vragen aan anderen, ik los het zelf wel op, ook al duurt het dan misschien iets langer.",
        170: "Ik durf wel eens een zondebok te zoeken als ik iets fout gedaan heb dat zware gevolgen kan hebben.",
        171: "Ik streef op het werk naar een sfeer waarbij men bij elkaar durft aan te kloppen en waarin iedereen respect heeft voor elkaar.",
        172: "Ik zal collega's altijd verdedigen in het openbaar, maar ik spreek mijn collega er wel op aan als er geen anderen bij zijn.",
        173: "Ik praat niet over collega's maar met collega's.",
        174: "Ik vind het niet nodig om de behoeften van mijn collega's te kennen; je bent immers op je werk om je job uit te voeren.",
        175: "Collega's helpen zorgt ervoor dat ik minder tijd heb om mijn eigen job te doen, dus ik vermijd dat als ik kan.",
        176: "Ik kan makkelijk beoordelen wanneer ik mijn gedrag moet aanpassen in een bepaalde situatie.",
        177: "Ik kan vlot prioriteiten bijstellen bij veranderende omstandigheden.",
        178: "Bij plotse verandering van opdrachten, zal ik mij flexibel aanpassen zonder in weerstand te gaan.",
        179: "Het is voor mij moeilijker om snel te schakelen als zaken veranderen.",
        180: "Ik houd er niet van als ik er een onverwachte opdracht krijg."
    }
};

export default texts;
