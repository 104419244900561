const texts = {
    explanation: `Je krijgt telkens een set van 3 uitspraken te zien.<br/>
    Klik deze uitspraken aan in de volgorde waarin ze jou meest aanspreken.`,
    explanation_question: `Klik deze uitspraken aan in de volgorde waarin ze jou meest aanspreken`,
    button_start: "Ga naar de uitspraken",
    button_next: "Volgende",
    button_again: "Opnieuw",
    questions: [
        {
            8: "Als ik mij erger of gefrustreerd ben, zal mijn omgeving dit snel merken; iedereen mag weten wat mij niet zint!  Ik steek mijn boosheid niet onder stoelen of banken en uit die meestal direct. Eens het eruit is, kan ik het snel vergeten en gaat het werk voor mij gewoon verder.",
            9: "Ik word heel zelden echt boos. Als ik boos ben, ga ik eerder in passieve weerstand i.p.v. mij direct te uiten. Eigenlijk hoop ik dat de situatie vanzelf overwaait zodat de harmonie en de sfeer niet verstoord worden. Het geeft mij immers rust als iedereen rondom mij tevreden is.",
            1: "Als ik mij erger of gefrustreerd ben, zal mijn omgeving dit vooral merken aan mijn  gelaatsuitdrukkingen of omdat ik bvb meer zucht. Ik zal ergernissen niet snel uiten en eerder opkroppen… en dan kan de boel wel eens ontploffen. Op mijn werk doe ik er alles aan mijn emoties onder controle te houden en zullen mijn ingehouden frustraties niet snel tot uiting komen. Thuis gebeurt dit wel sneller."
        },
        {
            2: "Ik ben een pleaser; ik ben er graag voor anderen. Ik wil graag iedereens vriend zijn. Ik voel immers aan wat anderen nodig hebben en ik help graag. Ik zal altijd eerst de anderen helpen vooraleer ik iets voor mijzelf doe.  Ik vind het fijn als anderen mij nodig hebben.",
            3: "Ik ben een winnaar. Falen is voor mij geen optie en dus streef ik ernaar succesvol te zijn. Eigenlijk ben ik een workaholic. Stilzitten is niets voor mij.",
            4: "Ik heb mood swings: het ene moment kan ik heel blij zijn en het andere moment voel ik mij weer eens niet begrepen. Het leven kan zwaar zijn. Oppervlakkige gesprekken zijn niet aan mij besteed : er moet diepgang zitten in mijn relaties met anderen. Het ergste zou zijn als anderen mij zien als grijze muis;  ik wil echt wel het verschil maken."
        },
        {
            5: "Ik kan mij lang focussen op hetzelfde onderwerp als het in mijn interesseveld ligt. Kennis opdoen over onderwerpen die mij interesseren, daar ben ik constant mee bezig – je kan immers nooit genoeg weten. Ik doe letterlijk een stap achteruit als ik benaderd word door een heel opdringerig persoon. Ik ben graag alleen en streef ernaar onafhankelijk te zijn.",
            6: "Ik ben graag voorbereid en leer graag bij. Zo zal ik eerst alle opties overwegen vooraleer een beslissing te nemen. Bij twijfel zal ik personen in mijn omgeving raadplegen wiens mening ik vertrouw. Ik word weleens een doemdenker genoemd omdat ik alle mogelijke scenario’s overweeg. Ik wil duidelijkheid en weten waar ik aan toe ben – vertrouwen is belangrijk; immers “samen sterk”!",
            7: "Saaiheid of routine is niets voor mij. Geef mij maar leuke ervaringen, het kunnen uiten van nieuwe ideeën en leuke vooruitzichten hebben! Ik hou van brainstormen en opties bespreken. Ik kan moeilijk kiezen omdat ik liever niets mis. Voor mij geen afgelijnde job met veel beperkingen en regels. Het leven moet plezant blijven!"
        }
    ]
};

export default texts;
