const texts = {
    button_next: "Volgende",
    questions: {
        1: {
            8: [
                {
                    text:
                        'Waarop focus jij <span class="important">eerder</span>?',
                    options: { 8: "op de grote lijnen", 1: "op de details" },
                    value: 3
                },
                {
                    text:
                        'Ben jij <span class="important">eerder</span> nonchalant of beheerst?',
                    options: { 8: "nonchalant", 1: "beheerst" },
                    value: 1
                },
                {
                    text:
                        'Zou je jezelf <span class="important">eerder</span> omschrijven als iemand die de regels volgt of als non-conformist?',
                    options: { 1: "de regels volgt", 8: "non-conformist" },
                    value: 1
                }
            ],
            9: [
                {
                    text:
                        'Zullen fouten van anderen je <span class="important">eerder</span> ergeren en frustreren of ben jij iemand die daarover niets zou zeggen en denken “het is zo erg niet”?',
                    options: {
                        1: "ergeren en frustreren",
                        9: "niets zou zeggen en denken “het is zo erg niet”"
                    },
                    value: 1
                },
                {
                    text:
                        'Ben je <span class="important">eerder</span> meelevend of benader je de zaken eerder rationeel?',
                    options: { 9: "meelevend", 1: "rationeel" },
                    value: 1
                },
                {
                    text:
                        'Waar heb jij <span class="important">meest</span> een hekel aan?',
                    options: {
                        9: "dat anderen je opjagen",
                        1: "dat anderen je bekritiseren"
                    },
                    value: 3
                }
            ]
        },
        8: {
            9: [
                {
                    text:
                        'Zeggen anderen <span class="important">eerder</span> dat je “te direct” bent of eerder “te meegaand”?',
                    options: { 8: "te direct", 9: "te meegaand" },
                    value: 1
                },
                {
                    text:
                        'Ben jij <span class="important">vooral</span> geduldig of eerder ongeduldig?',
                    options: { 9: "geduldig", 8: "eerder ongeduldig" },
                    value: 3
                },
                {
                    text:
                        'Wat vind je <span class="important">het ergst</span>?',
                    options: { 8: "machteloosheid", 9: "opdringerigheid" },
                    value: 1
                }
            ]
        },

        2: {
            3: [
                {
                    text:
                        'Waar voel jij je <span class="important">het slechts</span> bij? Door anderen gezien worden als egoïstisch of als onsuccesvol?',
                    options: { 2: "egoïstisch", 3: "onsuccesvol" },
                    value: 3
                },
                {
                    text:
                        'Waar heb jij <span class="important">meest</span> moeilijkheden mee?',
                    options: {
                        2: "afstandelijke houding van anderen",
                        3: "trage mensen"
                    },
                    value: 1
                },
                {
                    text:
                        "Als anderen een beeld van je schetsen, heb je dan liever dat ze zeggen dat je een behulpzaam persoon bent of dat ze je zien als competent en succesvol?",
                    options: {
                        2: "behulpzaam persoon",
                        3: "competent en succesvol"
                    },
                    value: 1
                }
            ],
            4: [
                {
                    text: 'Wat vind jij <span class="important">erger</span>?',
                    options: { 2: "de pleaser zijn", 4: "de grijze muis zijn" },
                    value: 1
                },
                {
                    text:
                        "Wil jij liefst een goede collega zijn of wil je liever écht het verschil maken in het team?",
                    options: {
                        2: "een goede collega zijn",
                        4: "het verschil maken in het team"
                    },
                    value: 1
                },
                {
                    text:
                        'Word je <span class="important">liever erkend</span> voor je behulpzaamheid of voor de persoon die je bent?',
                    options: {
                        2: "je behulpzaamheid",
                        4: "voor de persoon die je bent"
                    },
                    value: 3
                }
            ]
        },
        3: {
            4: [
                {
                    text:
                        'Waar hecht jij <span class="important">meer</span> belang aan?',
                    options: {
                        3: "efficiënt zijn",
                        4: "diepgang hebben in je werk"
                    },
                    value: 3
                },
                {
                    text: "Wat is het meest belangrijk voor jou:",
                    options: { 4: " introspectie", 3: "je carrière uitbouwen" },
                    value: 1
                },
                {
                    text: "Als iemand je emotioneel benadert, ga je dan:",
                    options: {
                        3: '<span class="important">eerder</span> afstand nemen',
                        4: '<span class="important">eerder</span> ondersteunen'
                    },
                    value: 1
                }
            ]
        },

        5: {
            6: [
                {
                    text: "Wat kies je?",
                    options: {
                        6: "samen sterk!",
                        5: "persoonlijke onafhankelijkheid"
                    },
                    value: 3
                },
                {
                    text: "Wat stoort jou meest?",
                    options: { 6: "einzelgängers ", 5: "opdringerige mensen" },
                    value: 1
                },
                {
                    text: "Waar heb jij het meest moeite mee:",
                    options: {
                        6: "onduidelijkheid",
                        5: "claimgedrag van anderen"
                    },
                    value: 1
                }
            ],
            7: [
                {
                    text:
                        "Wat heeft jouw voorkeur: veel kennen van iets of iets kennen over veel verschillende dingen?",
                    options: {
                        5: "veel kennen van iets",
                        7: "iets kennen over veel verschillende dingen"
                    },
                    value: 1
                },
                {
                    text: "Wat stoort jou meest: ",
                    options: {
                        5: "kortzichtigheid bij anderen",
                        7: "saaiheid"
                    },
                    value: 1
                },
                {
                    text:
                        "Kom je meestal kalm en rustig over op anderen of eerder vreugdevol en enthousiast?",
                    options: {
                        5: "kalm en rustig",
                        7: "vreugdevol en enthousiast"
                    },
                    value: 3
                }
            ]
        },
        6: {
            7: [
                {
                    text:
                        "In welke omgeving werk je liefst? In een omgeving waar een duidelijke structuur bestaat en er duidelijke richtlijnen en procedures zijn om je aan te houden of kies jij eerder voor een omgeving waarin je veel vrijheid krijgt in je doen en laten zonder duidelijke spelregels?",
                    options: {
                        6: "duidelijke richtlijnen en procedures",
                        7: "veel vrijheid en zonder duidelijke spelregels?"
                    },
                    value: 1
                },
                {
                    text:
                        "Waar hou je het meest van: voorspelbaarheid of flexibiliteit?",
                    options: { 6: "voorspelbaarheid", 7: "flexibiliteit" },
                    value: 1
                },
                {
                    text: "Waar kies je meest voor:",
                    options: { 6: "duidelijkheid", 7: "avontuur" },
                    value: 3
                }
            ]
        }
    }
}

export default texts;
