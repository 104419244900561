const texts = {
    explanation: `You will now receive multiple statements.<br />
    Indicate to what extent you recognize yourself in the following statements,<br />
    where 1 is least applicable and 5 is most applicable.
    <br />`,
    scale_left: "Disagree",
    scale_right: "Totally agree",
    button_next: "Next",
    button_start: "Go to the statements",
    questions: [
        {
            question: 1,
            text: "I'll do it quicky myself, then I know it's done right."
        },
        {
            question: 2,
            text:
                "I hate making mistakes. It blows me off. After all, I am very critical and check everything before considering something as “finished”."
        },
        {
            question: 3,
            text:
                "Rules are there to follow. It is not right to break rules. You just don't!"
        },
        {
            question: 4,
            text:
                "I can't understand why so many people have such loose standards."
        },
        {
            question: 5,
            text:
                "I find it hard not to want to interfere in everything, so that it might be done well."
        },
        {
            question: 6,
            text:
                "Most people see me as serious and businesslike…I usually come across that way."
        },

        {
            question: 7,
            text:
                "It's hard for me to say no because then I think I'm disappointing people and I avoid that."
        },
        {
            question: 8,
            text:
                "I take care of the other first and only then for myself. After all, I'm here to help others."
        },
        {
            question: 9,
            text:
                "If I don't feel a connection, I'm not functioning properly. I like to be a best friend to others and so I tend to please."
        },
        {
            question: 10,
            text:
                "Even if people are indifferent to me, I'm going to do everything I can to make these people like me."
        },
        {
            question: 11,
            text:
                "If I see a stray dog, I would prefer to take him home immediately."
        },
        {
            question: 12,
            text:
                "I don't like to take credit for the many things I do for others, but it can gnaw or even bother me if they don't notice or don't care."
        },

        {
            question: 13,
            text:
                "It's not OK not to be OK. That's why I always give the impression that everything is okay."
        },
        { question: 14, text: "Failing is not an option! I always want to win." },
        {
            question: 15,
            text:
                "I have a clear goal and I have to achieve it as efficiently (and preferably as quickly) as possible."
        },
        {
            question: 16,
            text:
                "I see myself as competent: I don't feel good if I'm not effective and efficient."
        },
        {
            question: 17,
            text:
                "It is important to me that I can feel successful, even if I do not yet have the great success that I aspire to."
        },
        {
            question: 18,
            text:
                "I seem like a workaholic, I feel useless and restless when I'm not trying to achieve something."
        },

        {
            question: 19,
            text:
                "Everything I do has to have a personal meaning. I long for depth in everything."
        },
        {
            question: 20,
            text: "Others don't understand me. I often feel misunderstood."
        },
        {
            question: 21,
            text:
                "Je ne veux pas être une souris grise ; Je veux vraiment faire la différence!"
        },
        {
            question: 22,
            text:
                "Beaucoup de gens me trouvent énigmatique, difficile et contradictoire – en fait j'aime ça."
        },
        {
            question: 23,
            text:
                "Apprendre à me connaître et être fidèle à mes besoins émotionnels ont toujours été des moteurs importants pour moi."
        },
        {
            question: 24,
            text:
                "Quand je suis critiqué ou incompris, j'ai tendance à me retirer et même à bouder."
        },

        {
            question: 25,
            text: "Je préfère séparer ma vie privée de mon travail."
        },
        {
            question: 26,
            text:
                "La compréhension des choses est importante pour moi ; Je veux « savoir » et je chercherai jusqu'à ce que j'aie plus de connaissances ; vous ne pouvez jamais en savoir assez."
        },
        {
            question: 27,
            text:
                "Je peux me concentrer pendant des heures, voire des jours sur le sujet qui m'intéresse. Je peux facilement être seul pendant longtemps."
        },
        {
            question: 28,
            text:
                "Ce n'est que sur la base d'informations précises que l'on peut prendre une décision rationnelle. Cependant, la plupart des gens ne sont pas très rationnels."
        },
        {
            question: 29,
            text:
                "La plupart des gens sont si incroyablement stupides que c'est presque un miracle que tout se passe bien."
        },
        {
            question: 30,
            text:
                "Je suis très curieux et j'aime explorer pourquoi les choses sont comme elles sont. Même les choses les plus évidentes ne sont pas si évidentes lorsque vous les étudiez réellement."
        },

        {
            question: 31,
            text:
                "J'ai un grand sens des responsabilités et je pense qu'il est très important de travailler ensemble dans la confiance."
        },
        { question: 32, text: "Je m'angoisse assez facilement et j'ai souvent des doutes." },
        {
            question: 33,
            text:
                "J'aime être prêt à tout et ne me sens à l'aise que lorsque je sais où je me situe et que tout est clair. J'aime être préparé à l'avance pour les choses ou les situations possibles."
        },
        {
            question: 34,
            text:
                "L'autorité m'attire. En même temps, je m'en méfie."
        },
        {
            question: 35,
            text:
                "Je me sens plus en confiance si je sais clairement ce que l'on attend de moi que si je travaillais seul."
        },
        {
            question: 36,
            text:
                "Avant de prendre une décision importante, je vérifie auprès de personnes en qui j'ai confiance avant de prendre une décision efficace. Habituellement, je sais à l'avance quelle serait ma décision, mais ça fait du bien d'avoir une confirmation."
        },

        {
            question: 37,
            text: "La structure et la routine sont ennuyeuses, alors je les déteste."
        },
        {
            question: 38,
            text:
                "Ma tête est pleine d'idées ; Je suis très doué pour réfléchir à des choses amusantes et/ou nouvelles."
        },
        {
            question: 39,
            text:
                "Je veux être partout et ne pas manquer des choses et/ou des expériences amusantes. Imaginez si je ratais quelque chose d'amusant ! Je détesterais vraiment ça."
        },
        {
            question: 40,
            text:
                "J'aime voyager et l'aventure et j'aime découvrir d'autres cultures et acquérir de nouvelles expériences. Après tout, le monde est une fantastique source de nouveaux stimuli."
        },
        {
            question: 41,
            text:
                "D'habitude j'ai un agenda chargé et je préfère le garder comme ça parce que je ne veux pas m'ennuyer."
        },
        {
            question: 42,
            text:
                "Les détails ne sont pas pour moi. Je me sens mieux dans l'ensemble. Après tout, il est bien plus amusant de réfléchir à de nouvelles idées que de participer à leur mise en œuvre."
        },

        { question: 43, text: "Personne ne décide pour moi; c'est ma vie!" },
        { question: 44, text: "Ne te montre pas faible, continue!" },
        {
            question: 45,
            text:
                "C’est moi qui décide. Quand les gens essaient de me commander, je ressens une résistance. Garder le contrôle de mes propres actions est important – j'aime avoir le contrôle!"
        },
        {
            question: 46,
            text:
                "Je ne peux pas sympathiser avec les faibles et les personnes qui n'osent pas immédiatement exprimer leur propre opinion. La faiblesse ne cause vraiment que des problèmes."
        },
        {
            question: 47,
            text:
                " Dans cette situation, je suis vraiment très fier : lorsque les personnes que j'ai eues sous mon aile grandissent en tant que personnes et réussissent dans les affaires ou dans leur travail."
        },
        {
            question: 48,
            text:
                "J'éprouve un immense sentiment d'accomplissement lorsque je fais quelque chose que les autres considèrent comme impossible. J'aime marcher sur le bord et voir que, contre toute attente, je réussis."
        },

        {
            question: 49,
            text:
                "Je suis une personne qui suit le courant; J'évite les conflits et préfère rester sous le radar."
        },
        {
            question: 50,
            text:
                "Vous ne pouvez pas me presser; Je ne me précipite pas et je fais tout à mon rythme. Je procrastine parfois, mais lorsqu'une échéance approche, je l'accélère d'un cran."
        },
        {
            question: 51,
            text:
                "Je suis heureux et satisfait lorsque les gens autour de moi sont heureux et satisfaits. Cela me donne la paix."
        },
        {
            question: 52,
            text: "Je préfère donner à quelqu'un ce qu'il veut plutôt que de faire une scène."
        },
        {
            question: 53,
            text:
                "Les gens disent parfois que je semble absent ou distrait. Le fait est que je comprends très bien les choses mais que je ne veux tout simplement pas y réagir parce que je ne veux pas troubler ma paix intérieure."
        },
        {
            question: 54,
            text:
                "Il faut se résigner à ce que la vie apporte. Vous ne pouvez pas y faire grand-chose de toute façon."
        }
    ]
};

export default texts;
